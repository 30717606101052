// const ThemeOptions = {
//   palette: {
//     // mode: "light",
//     primary: {
//       main: "rgb(17,70,138)",
// light: "#67C5D6"
//     },
//     secondary: {
//       main: "#D6678D",
//     },
//     error: {
//       main: "#e6492f",
//     },
//     warning: {
//       main: "#ffad3e",
//     },
//     info: {
//       main: "#f5c505",
//     },
//     success: {
//       main: "#8DD667",
//        dark: "rgb(99,184,130)"
//     },
//     divider: "rgba(121,116,116,0.12)",
//   },
//   typography: {
//     fontFamily: [
//       "Montserrat",
//       "Roboto",
//       '"Helvetica Neue"',
//       "Arial",
//       "sans-serif",
//     ].join(","),
//   },
// };

// export default ThemeOptions;

const ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#678dd6",
    },
    secondary: {
      main: "#D6678D",
    },
    text: {
      primary: "rgba(0,0,0,0.87)",
    },
    error: {
      main: "#e6492f",
    },
    warning: {
      main: "#ffad3e",
    },
    info: {
      main: "#f5c505",
    },
    success: {
      main: "#8DD667",
    },
    news: { main: "#D6678D" },
    child: { main: "#67C5D6" },
    childrenInProduction: { main: "#8DD667" },
    divider: "rgba(121,116,116,0.12)",
  },
  typography: {
    typography: {
      fontFamily: [
        "Montserrat",
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
    },
  },
};

export default ThemeOptions;

import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Unstable_Grid2 as Grid,
  Stack,
  Button,
} from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";

import { BasicPageLayout } from "../../layouts/BasicPageLayout";
import CardUniversalChildrenData from "../../components/Cards/CardContactsData";
import CardPersonDetails from "../../components/Cards/CardPersonDetails";

import { token, isAuth } from "../../athoms/auth";
import { useLocation } from "react-router-dom";
import CardAddressesData from "../../components/Cards/CardAddressesData";
import CardClientCollectingsData from "../../components/Cards/CardClientCollectingsData";
import ModalNewRipartizione from "../../components/Modals/ModalNewRipartizione.js";

const AnagraficaEdit = () => {
  let isNew = false;
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);
  const personDataFromAnagrafica = useLocation().state ?? generateBlankPerson();
  const [personData, setPersonData] = useState(personDataFromAnagrafica);
  const [open, setOpen] = useState({ open: false, personData: {} });

  function generateBlankPerson() {
    isNew = true;
    return {
      tipoArchivio: "",
      nome: "",
      cognome: "",
      tipoPersona: "",
      settore: "",
      ruolo: "",
      collecting: [],
      recapiti: [],
      indirizzi: [],
    };
  }
  const closeModal = () => setOpen({ open: false });

  return (
    <>
      <BasicPageLayout>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8.5,
          }}
        >
          <Container maxWidth="xl">
            <Stack>
              {!isNew && (
                <Button
                  onClick={() => setOpen({ open: true })}
                  size="medium"
                  variant="contained"
                  sx={{
                    marginRight: { lg: "auto" },
                    mb: 3,
                    color: "white",
                  }}
                >
                  NUOVA RIPARTIZIONE
                </Button>
              )}

              <Grid container spacing={3}>
                <Grid xs={12}>
                  <CardPersonDetails
                    setPersonData={setPersonData}
                    personData={personData}
                    isNew={isNew}
                  />
                </Grid>
                {console.log(personData)}
                <Grid xs={6}>
                  <CardUniversalChildrenData
                    setPersonData={setPersonData}
                    personData={personData}
                  />
                </Grid>
                <Grid xs={6}>
                  <CardAddressesData
                    setPersonData={setPersonData}
                    personData={personData}
                  />
                </Grid>
                <Grid xs={6}>
                  <CardClientCollectingsData
                    setPersonData={setPersonData}
                    personData={personData}
                  />
                </Grid>
                <Grid xs={12}></Grid>
              </Grid>
            </Stack>
          </Container>
        </Box>
      </BasicPageLayout>
      {open.open && (
        <ModalNewRipartizione
          open={open.open}
          closeModal={closeModal}
          personData={personData}
        />
      )}
    </>
  );
};

export default AnagraficaEdit;

import PropTypes from "prop-types";
import Bars3Icon from "@heroicons/react/24/solid/Bars3Icon";
import {
  Avatar,
  Box,
  IconButton,
  Stack,
  SvgIcon,
  useMediaQuery,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { usePopover } from "../../hooks/use-popover";
import { AccountPopover } from "./account-popover";
import { useRecoilValue } from "recoil";
import { user } from "../../athoms/auth";

const SIDE_NAV_WIDTH = 280;
const TOP_NAV_HEIGHT = 64;

export const TopNav = (props) => {
  const { onNavOpen } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const accountPopover = usePopover();
  const userData = useRecoilValue(user);

  return (
    <>
      <Box
        component="header"
        sx={{
          backdropFilter: "blur(6px)",
          backgroundColor: (theme) =>
            alpha(theme.palette.background.default, 0.8),
          position: "fixed",
          left: {
            lg: `${SIDE_NAV_WIDTH}px`,
          },
          top: 0,
          width: {
            lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
            xs: "100%",
          },

          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 2,
          }}
        >
          {" "}
          {lgUp && (
            <>
              {" "}
              <Stack alignItems="center" direction="row" spacing={2}></Stack>
              <Stack alignItems="center" direction="row" spacing={2}>
                <Avatar
                  onClick={accountPopover.handleOpen}
                  ref={accountPopover.anchorRef}
                  sx={{
                    cursor: "pointer",
                    height: 40,
                    width: 40,
                    bgcolor: "primary.main",
                  }}
                >
                  {`${
                    userData.username ? userData.username[0].toUpperCase() : ""
                  }`}
                </Avatar>
              </Stack>
            </>
          )}
          {!lgUp && (
            <>
              <Stack alignItems="center" direction="row" spacing={2}>
                <Avatar
                  onClick={accountPopover.handleOpen}
                  ref={accountPopover.anchorRef}
                  sx={{
                    cursor: "pointer",
                    height: 40,
                    width: 40,
                    bgcolor: "primary.main",
                  }}
                >
                  {`${userData.username ? userData.username[0] : ""}`}
                </Avatar>
              </Stack>
              <Stack alignItems="center" direction="row" spacing={2}>
                <IconButton onClick={onNavOpen}>
                  <SvgIcon fontSize="large">
                    <Bars3Icon />
                  </SvgIcon>
                </IconButton>
              </Stack>
            </>
          )}
        </Stack>
      </Box>
      <AccountPopover
        anchorEl={accountPopover.anchorRef.current}
        open={accountPopover.open}
        onClose={accountPopover.handleClose}
        visual={userData.visual}
      />
    </>
  );
};

TopNav.propTypes = {
  onNavOpen: PropTypes.func,
};

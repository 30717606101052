import HomeModernIcon from "@heroicons/react/24/solid/HomeModernIcon";
import {
  Avatar,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  Typography,
  Container,
} from "@mui/material";
import { FormattedNumber } from "react-intl";

const CardRipartizioniTotalGain = ({ collectingJobs }) => {
  return (
    <Card
      sx={{
        height: "100%",
        boxShadow:
          "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
        borderRadius: 3,
        textAlign: "center",
      }}
    >
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="center"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="overline">
              Guadagno totale
            </Typography>
            <Typography variant="h5" color="secondary.main">
              <FormattedNumber
                value={totalGain(collectingJobs)}
                currency="Eur"
                style="currency"
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

const totalGain = (jobs) => {
  if (jobs.length === 0) {
    return 0;
  }
  return jobs.reduce(function (acc, next) {
    return acc + next.guadagno;
  }, 0);
};

export default CardRipartizioniTotalGain;

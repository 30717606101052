import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  TextField,
  Paper,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import standardRequestGenerator from "../../../helpers/httpHelperV1";

import { useRecoilState, useRecoilValue } from "recoil";

import { user, token, isAuth } from "../../../athoms/auth";
import DeleteAction from "../../IconActions/DeleteAction";
import UpdateAction from "../../IconActions/UpdateAction";

const AddressesData = ({
  addressData,
  addressTypes,
  setPersonData,
  personData,
  newRow,
  setNewRow,
}) => {
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);
  const [modifiedValues, setModifiedValues] = useState({});

  const [sent, setSent] = useState(false);
  const variant = "outlined";

  const onChangeField = (e) => {
    setModifiedValues({
      ...modifiedValues,
      [e.target.name]: e.target.value,
    });
    e.preventDefault();
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  const onUpdate = (e) => {
    const removeAddress = standardRequestGenerator({
      url: `auto/people/${personData._id}`,
      token: tokenState,
      isAuthState: setIsAuthState,
      queryType: "REMOVE",
      body: { indirizzi: [addressData._id] },
    });
    const addAddress = standardRequestGenerator({
      url: `auto/people/${personData._id}`,
      token: tokenState,
      isAuthState: setIsAuthState,
      queryType: "ADD",
      body: {
        indirizzi: [
          {
            tipo: addressData.tipo,
            toponimo: addressData.toponimo,
            cap: addressData.cap,
            comune: addressData.comune,
            provincia: addressData.provincia,
            regione: addressData.regione,
            stato: addressData.stato,
            ...modifiedValues,
          },
        ],
      },
    });
    if (newRow) {
      addAddress()
        .then((e) => {
          setPersonData(e.body.rows[0]);
          setNewRow(false);
        })
        .catch((e) => alert("Compilare tutti i campi richiesti"));
    } else {
      removeAddress()
        .then(() => addAddress())
        .then((e) => setPersonData(e.body.rows[0]));
    }
  };

  const onDelete = (e) => {
    const removeAddress = standardRequestGenerator({
      url: `auto/people/${personData._id}`,
      token: tokenState,
      isAuthState: setIsAuthState,
      queryType: "REMOVE",
      body: { indirizzi: [addressData._id] },
    });
    removeAddress().then(() => {
      setPersonData({
        ...personData,
        indirizzi: personData.indirizzi.filter(
          (e) => e._id !== addressData._id
        ),
      });
    });
    e.preventDefault();
  };

  return (
    <Paper
      elevation={0}
      sx={{
        boxShadow:
          "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
      }}
    >
      <Accordion
        sx={{
          boxShadow:
            "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
          borderRadius: 3,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{ textTransform: addressData.CSSTextTransform?.tipo ?? "" }}
          >
            {addressData.tipo}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form autoComplete="off" noValidate onSubmit={onSubmit}>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item xs={4}>
                <Select
                  onChange={onChangeField}
                  size="small"
                  fullWidth={true}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={modifiedValues?.tipo ?? addressData.tipo}
                  name="tipo"
                  variant={variant}
                >
                  {addressTypes.map((e) => (
                    <MenuItem key={e} value={e}>
                      <Typography
                        sx={{
                          textTransform:
                            addressData.CSSTextTransform?.tipo ?? "",
                        }}
                      >
                        {e}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  inputProps={{
                    style: {
                      textTransform:
                        addressData.CSSTextTransform?.toponimo ?? "",
                    },
                  }}
                  value={
                    modifiedValues?.toponimo ?? addressData?.toponimo ?? ""
                  }
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.contatto ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  size="small"
                  type="text"
                  name="toponimo"
                  label="toponimo"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  inputProps={{
                    style: {
                      textTransform: addressData.CSSTextTransform?.cap ?? "",
                    },
                  }}
                  value={modifiedValues?.cap ?? addressData?.cap ?? ""}
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.contatto ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  size="small"
                  type="text"
                  name="cap"
                  label="cap"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  inputProps={{
                    style: {
                      textTransform: addressData.CSSTextTransform?.comune ?? "",
                    },
                  }}
                  value={modifiedValues?.comune ?? addressData?.comune ?? ""}
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.contatto ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  size="small"
                  type="text"
                  name="comune"
                  label="comune"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  inputProps={{
                    style: {
                      textTransform:
                        addressData.CSSTextTransform?.provincia ?? "",
                    },
                  }}
                  value={
                    modifiedValues?.provincia ?? addressData?.provincia ?? ""
                  }
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.contatto ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  size="small"
                  type="text"
                  name="provincia"
                  label="provincia"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  inputProps={{
                    style: {
                      textTransform:
                        addressData.CSSTextTransform?.regione ?? "",
                    },
                  }}
                  value={modifiedValues?.regione ?? addressData?.regione ?? ""}
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.contatto ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  size="small"
                  type="text"
                  name="regione"
                  label="regione"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  inputProps={{
                    style: {
                      textTransform: addressData.CSSTextTransform?.stato ?? "",
                    },
                  }}
                  value={modifiedValues?.stato ?? addressData?.stato ?? ""}
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.contatto ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  size="small"
                  type="text"
                  name="stato"
                  label="stato"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={1}>
                {Object.keys(modifiedValues).length > 0 && !sent && (
                  <UpdateAction onUpdate={onUpdate} />
                )}
              </Grid>
              <Grid item xs={1}>
                <DeleteAction onDelete={onDelete} />
              </Grid>
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

export default AddressesData;

import React from "react";

import GradientBackground from "../../components/GradientBackround";
import LogInCard from "../../components/Cards/LoginCard";

const LogInPage = () => {
  return (
    <GradientBackground>
      <LogInCard />
    </GradientBackground>
  );
};

export default LogInPage;

import React, { useState, useEffect } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/it";
import dayjs, { Dayjs } from "dayjs";
import standardRequestGenerator from "../../../helpers/httpHelperV1";
import { user, token, isAuth } from "../../../athoms/auth";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  Unstable_Grid2 as Grid,
  TextField,
  Button,
  Typography,
  Divider,
  Stack,
  InputAdornment,
  Box,
  Modal,
  Container,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid",
  borderColor: "primary.main",
  boxShadow: 24,
  borderRadius: 3,
  pt: 2,
  px: 4,
  pb: 3,
};

const ModalNewInvoice = ({
  open,
  closeModal,
  collectingJobData,
  deleteFromRow,
}) => {
  const [okToSend, setOkToSend] = useState(false);
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);
  const [modifiedValues, setModifiedValues] = useState({});
  const variant = "outlined";

  useEffect(() => {
    if (okToSend && isAuthState) {
      const patchRipartizione = standardRequestGenerator({
        url: `auto/collectingJobs/${collectingJobData._id}`,
        queryType: "PATCH",
        isAuthState: setIsAuthState,
        token: tokenState,
        body: {
          ...modifiedValues,
          stato: "daincassare",
        },
      });
      patchRipartizione()
        .then(() => deleteFromRow(collectingJobData._id))
        .then(() => closeModal());
    }
  }, [okToSend, isAuthState]);

  const onSubmit = (e) => {
    e.preventDefault();
    setOkToSend(true);
  };

  const onChangeDate = (val) => {
    const date = dayjs(val);
    const timestamp = date.$d.getTime();
    setModifiedValues({
      ...modifiedValues,
      dataFatturaLaManu: timestamp,
    });
  };

  const onChangeField = (e) => {
    setModifiedValues({
      ...modifiedValues,
      [e.target.name]: e.target.value,
    });
    e.preventDefault();
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <form onSubmit={onSubmit}>
        <Box sx={{ ...style, width: "45vw", maxHeight: "70vh" }}>
          <Stack spacing={2} padding={1}>
            <Typography
              color={"primary.main"}
              id="parent-modal-title"
              sx={{
                fontSize: "1.2rem",
                fontWeight: 500,
              }}
            >
              {`Inserisci dati fattura La Manu`}
            </Typography>
            <Divider />

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                verticalAlign: "middle",
              }}
            >
              <Container>
                <Grid container spacing={2} justifyContent={"left"}>
                  <Grid item xs={3}>
                    <TextField
                      value={modifiedValues?.numeroFatturaLaManu ?? ""}
                      onChange={onChangeField}
                      fullWidth={true}
                      size="small"
                      type="text"
                      name="numeroFatturaLaManu"
                      label="Numero fattura"
                      variant={variant}
                      inputProps={{
                        style: {
                          textTransform: "capitalize",
                        },
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={"it"}
                    >
                      <MobileDateTimePicker
                        slotProps={{
                          textField: { size: "small", fullWidth: true },
                        }}
                        label="Data fattura"
                        value={convertDate(modifiedValues.dataFatturaLaManu)}
                        onChange={onChangeDate}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Container>
            </Box>
            <Divider />
            <Stack direction={"row-reverse"} justifyItems={"flex-end"}>
              <Button
                type="submit"
                sx={{ color: "white" }}
                variant="contained"
                onSubmit={onSubmit}
              >
                {"INSERISCI"}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </form>
    </Modal>
  );
};

function convertDate(collectingJobDataDate, modifiedValuesDate) {
  if (collectingJobDataDate) {
    return dayjs(collectingJobDataDate);
  } else if (modifiedValuesDate) {
    return dayjs(modifiedValuesDate);
  } else {
    return undefined;
  }
}

export default ModalNewInvoice;

import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Unstable_Grid2 as Grid,
  Stack,
  useTheme,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import { useRecoilState, useRecoilValue } from "recoil";

import { user, token, isAuth } from "../../athoms/auth";
import standardRequestGenerator from "../../helpers/httpHelperV1";

import { BasicPageLayout } from "../../layouts/BasicPageLayout";
import GuadagniRipartizioniTable from "../../components/Tables/GuadagniRipartizioniTable";
import CardRipartizioniTotalGain from "../../components/Cards/CardRipartizioniTotalGain";

const GuadagniPage = () => {
  const [status, setStatus] = useState("tutte");
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);

  const [collectingJobs, setCollectingJobs] = useState([]);

  const handleChange = (e, newValue) => {
    e.preventDefault();
    setStatus(newValue);
  };

  useEffect(() => {
    if (isAuthState) {
      if (status === "nessuna") {
        return () => {};
      } else if (status === "tutte") {
        const getGuadagniApiV1 = standardRequestGenerator({
          url: "auto/collectingJobsGain",
          queryType: "GET",
          token: tokenState,
          isAuthState: setIsAuthState,
        });
        getGuadagniApiV1().then((e) => setCollectingJobs(e.body.rows));
      } else {
        const getGuadagniApiV1 = standardRequestGenerator({
          url: "auto/collectingJobsGain",
          queryType: "GET",
          genericAndFilters: [{ collecting: status }],
          token: tokenState,
          isAuthState: setIsAuthState,
        });
        getGuadagniApiV1().then((e) => setCollectingJobs(e.body.rows));
      }
    } else {
      return () => {};
    }
  }, [isAuthState, status]);

  return (
    <BasicPageLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8.5,
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid xs={2}>
              <CardRipartizioniTotalGain collectingJobs={collectingJobs} />
            </Grid>
            <Grid xs={12}>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <Tabs
                  value={status}
                  onChange={handleChange}
                  textColor="primary"
                  indicatorColor="primary"
                  aria-label="secondary tabs example"
                >
                  <Tab value={"tutte"} label={"Tutte"} />
                  <Tab value={"nuovo imaie"} label={"Nuovo Imaie"} />
                  <Tab value={"aie"} label={"Aie"} />
                  <Tab value={"spedidam"} label={"Spedidam"} />
                  <Tab value={"swissperfom"} label={"Swissperfom"} />
                  <Tab value={"playright"} label={"Playright"} />
                </Tabs>
              </Box>
            </Grid>

            <Grid xs={12}>
              <GuadagniRipartizioniTable collectingJobsData={collectingJobs} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </BasicPageLayout>
  );
};

export default GuadagniPage;

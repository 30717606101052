import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Paper,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { ReactId } from "reactjs-id";
import AddressesData from "../../SingleData/AddressesData";
import standardRequestGenerator from "../../../helpers/httpHelperV1";
import { useRecoilState, useRecoilValue } from "recoil";

import { user, token, isAuth } from "../../../athoms/auth";

const CardAddressesData = ({ setPersonData, personData }) => {
  const [addressTypes, setAddressTypes] = useState([]);
  const [newRow, setNewRow] = useState(false);
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);
  const userState = useRecoilValue(user);
  const variant = "outlined";
  const childrenData = personData.indirizzi;

  useEffect(() => {
    if (isAuthState) {
      const getAddressType = standardRequestGenerator({
        token: tokenState,
        url: "auto/people",
        queryType: "LIST",
        additionalFields: ["indirizzi.tipo"],
        isAuthState: setIsAuthState,
      });
      getAddressType().then((e) => setAddressTypes(e.body.rows));
    }
  }, [isAuthState]);

  const onAddClick = (e) => {
    e.preventDefault();
    personData.indirizzi.push({});
    setPersonData(personData);
    setNewRow(true);
  };

  return (
    <Card
      sx={{
        boxShadow:
          "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
        borderRadius: 3,
      }}
    >
      <CardHeader
        title={"Indirizzi"}
        titleTypographyProps={{
          variant: "overline",
          fontSize: "1.15rem",
          color: "#424242",
        }}
        subheaderTypographyProps={{
          variant: "caption",
          fontSize: ".75rem",
          color: "#616161",
          mt: "-.55rem",
          mb: ".0rem",
        }}
      />
      <CardContent sx={{ pt: 0 }}>
        <Box
          sx={{
            p: 0.3,
            m: -1.5,
          }}
        >
          <Stack spacing={1}>
            {childrenData.map((e) => (
              <AddressesData
                addressData={e}
                key={e._id ?? ReactId()}
                addressTypes={addressTypes}
                setPersonData={setPersonData}
                personData={personData}
                newRow={newRow}
                setNewRow={setNewRow}
              />
            ))}
          </Stack>
        </Box>
      </CardContent>
      <Divider />
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button variant={"outlined"} color="primary" onClick={onAddClick}>
          {"aggiungi"}
        </Button>
      </CardActions>
    </Card>
  );
};

export default CardAddressesData;

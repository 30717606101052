import React, { useMemo, useState } from "react";
import {
  Typography,
  CardActions,
  CardContent,
  CardHeader,
} from "@mui/material";
import {
  DataGrid,
  itIT,
  GridToolbar,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { FormattedDate, FormattedNumber } from "react-intl";
import { useRecoilState, useRecoilValue } from "recoil";

import DeleteAction from "../../IconActions/DeleteAction";
import UpdateAction from "../../IconActions/UpdateAction";
import ModalNewInvoice from "../../Modals/ModalNewInvoice";

// import { useNavigate } from "react-router-dom";
import { user, token, isAuth } from "../../../athoms/auth";
import standardRequestGenerator from "../../../helpers/httpHelperV1";

const FatturabiliRipartizioniTable = ({
  collectingJobsData,
  setCollectingJobsData,
}) => {
  const [paginationState, setPaginationState] = useState(10);
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);
  const [open, setOpen] = useState({ open: false, collectingJobData: {} });
  // const navigate = useNavigate();

  // const onCLick = (data, e) => {
  //   e.preventDefault();
  //   navigate(`/ripartizioni/${data.id}`, {
  //     state: collectingJobsData.filter((e) => e._id === data.id)[0],
  //   });
  // };

  const onDelete = (params) => {
    return () => {
      const deleteCollectingJobsApi = standardRequestGenerator({
        url: `auto/collectingJobs/${params.id}`,
        queryType: "DELETE",
        token: tokenState,
        isAuthState: setIsAuthState,
        orderBy: "clienteId",
        orderRule: "ASC",
        body: {},
      });
      deleteCollectingJobsApi().then(() => deleteFromRow(params.id));
    };
  };

  const deleteFromRow = (id) => {
    setCollectingJobsData(collectingJobsData.filter((e) => e._id !== id));
  };

  const onAdd = (params) => {
    return () => {
      setOpen({
        open: true,
        collectingJobData: params.row.fullObject,
      });
    };
  };

  const closeModal = () => setOpen({ open: false });

  const recordsFromApi = collectingJobsData.map((e) => {
    return {
      id: e._id,
      cliente: {
        value: e.clienteId.visual,
        css: e.clienteId.CSSTextTransform.visual,
      },
      collecting: { value: e.collecting, css: e.CSSTextTransform.collecting },
      dataRipartizione: e.dataRipartizione,
      importoLordo: e.importoLordo,
      importoNetto: e.importoNetto,
      percentualeLaManu: e.percentualeLaManu,
      provvigioneLaManu: e.provvigioneLaManu,
      protocollo: e.protocollo,
      dataRicezioneRipartizioneCliente: e.dataRicezioneRipartizioneCliente,
      fullObject: e,
    };
  });

  const columns = useMemo(
    () => [
      {
        field: "cliente",
        headerName: "CLIENTE",
        width: 200,
        renderCell: (e) => (
          <Typography variant={"body"} sx={{ textTransform: e.value.css }}>
            {e.value.value}
          </Typography>
        ),
      },
      {
        field: "percentualeLaManu",
        headerName: "%",
        width: 40,
        renderCell: (e) => (
          <FormattedNumber
            value={e.value.toString()}
            // style="percentage"
            style="currency"
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
        ),
      },
      {
        field: "collecting",
        headerName: "COLLECTING",
        width: 140,
        renderCell: (e) => (
          <Typography variant={"body"} sx={{ textTransform: e.value.css }}>
            {e.value.value}
          </Typography>
        ),
      },
      {
        field: "protocollo",
        headerName: "PROTOCOLLO",
        width: 140,
        renderCell: (e) => (
          <Typography variant={"body"} sx={{ textTransform: "uppercase" }}>
            {e.value}
          </Typography>
        ),
      },
      {
        field: "dataRipartizione",
        headerName: "DATA RIP.",
        width: 120,
        renderCell: (e) => (
          <FormattedDate value={e.value} timeZone="Europe/Rome" />
        ),
      },
      {
        field: "dataRicezioneRipartizioneCliente",
        headerName: "INCASSO CLIENTE",
        width: 170,
        renderCell: (e) => (
          <FormattedDate value={e.value} timeZone="Europe/Rome" />
        ),
      },

      {
        field: "importoLordo",
        headerName: "LORDO",
        width: 110,
        renderCell: (e) => (
          <FormattedNumber
            value={e.value.toString()}
            style="currency"
            currency="Eur"
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
        ),
      },
      {
        field: "importoNetto",
        headerName: "NETTO",
        width: 110,
        renderCell: (e) => (
          <FormattedNumber
            value={e.value}
            style="currency"
            currency="Eur"
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
        ),
      },

      {
        field: "provvigioneLaManu",
        headerName: "LA MANU",
        width: 140,
        renderCell: (e) => (
          <FormattedNumber
            value={e.value.toString()}
            style="currency"
            currency="Eur"
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
        ),
      },
      {
        field: "delete",
        type: "actions",
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={<UpdateAction />}
              onClick={onAdd(params)}
              label="pay"
            />,
            <GridActionsCellItem
              icon={<DeleteAction />}
              onClick={onDelete(params)}
              label="pay"
            />,
          ];
        },
      },
    ],
    []
  );

  return (
    <>
      <DataGrid
        showCellVerticalBorder={false}
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
        rows={recordsFromApi}
        getRowId={(row) => row.id}
        initialState={{
          ...recordsFromApi,
          pagination: {
            paginationModel: { pageSize: paginationState, page: 0 },
          },
        }}
        pageSizeOptions={[5, 10, 20, 50]}
        sx={{
          boxShadow:
            "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
          borderRadius: 3,
          border: 0,
        }}
        // onRowDoubleClick={onCLick}
        slots={{
          toolbar: () => <GridToolbar sx={{ mb: 2 }} />,
        }}
      />
      {open.open && (
        <ModalNewInvoice
          open={open.open}
          closeModal={closeModal}
          collectingJobData={open.collectingJobData}
          deleteFromRow={deleteFromRow}
        />
      )}
    </>
  );
};

export default FatturabiliRipartizioniTable;

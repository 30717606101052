import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Unstable_Grid2 as Grid,
  TextField,
  Button,
  Paper,
  Container,
  ImageListItem,
  Stack,
  Divider,
} from "@mui/material";

import { postRequestGenerator } from "../../../helpers/httpHelper";

import Logo from "../../../assets/images/LOGO-FOOTER.png";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";

const SignUpCard = ({ commercialFlagExtId }) => {
  const variant = "outlined";
  const [step, setStep] = useState(1);
  const [okToSend, setOkToSend] = useState(false);
  const [formData, setFormData] = useState(
    generateInitialState(commercialFlagExtId)
  );
  const navigate = useNavigate();

  const submitHandler = (e) => {
    if (formData.privacy && formData.eula) {
      setOkToSend(true);
    } else {
      alert(
        "E' obbligatorio accettare i termini e le condizioni d'uso e la Privacy"
      );
    }

    e.preventDefault();
  };

  const onChangeField = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    e.preventDefault();
  };

  useEffect(() => {
    if (okToSend) {
      const sendNewCommercialFlag = postRequestGenerator({
        url: "commercialFlag/signUp",
        queryType: "POST",
        tokenState: "",
        isAuthState: (a) => a,
        body: generateBody(formData),
      });
      sendNewCommercialFlag()
        .then(() => navigate("/logIn"))
        .catch((e) => console.log(e));
    }
  }, [okToSend]);

  return (
    <Paper
      elevation={16}
      sx={{
        opacity: 0.9,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        // textAlign: "center",
        width: {
          xs: "90%",
          sm: "85%",
          md: "75%",
          lg: "60%",
          xl: "55%",
        },
        margin: "0 auto",
      }}
    >
      <Stack alignItems="center" justifyContent="flex-start">
        <ImageListItem>
          <Container>
            <img src={Logo} style={{ width: 200, marginBottom: 25 }}></img>
          </Container>
        </ImageListItem>
        <Container>
          {(() => {
            switch (step) {
              case 1:
                return (
                  <Step1
                    step={step}
                    setStep={setStep}
                    onChangeField={onChangeField}
                    formData={formData}
                    variant={variant}
                  />
                );
              case 2:
                return (
                  <Step2
                    step={step}
                    setStep={setStep}
                    onChangeField={onChangeField}
                    formData={formData}
                    variant={variant}
                  />
                );
              case 3:
                return (
                  <Step3
                    step={step}
                    setStep={setStep}
                    onChangeField={onChangeField}
                    formData={formData}
                    variant={variant}
                    submitHandler={submitHandler}
                    setFormData={setFormData}
                  />
                );
              default:
                return null;
            }
          })()}
        </Container>
      </Stack>
    </Paper>
  );
};

function generateInitialState(commercialFlagExtId) {
  return {
    commercialFlagExtId: parseInt(commercialFlagExtId),
    personType: 0,
    name: "",
    surname: "",
    businessName: "",
    fiscalCode: "",
    vat: "",
    sdi: "",
    username: "",
    password: "",
    passwordRepeat: "",
    iban: "",
    status: 0,
    permission: 4,
    privacy: false,
    eula: false,
    promo: false,

    email: "",
    emailType: 2,
    telephone: "",
    telephoneType: 0,

    jobType: 3,

    address: "",
    number: "",
    locality: "",
    zip: "",
    addressType: 0,
  };
}

function generateBody(formData) {
  return {
    people: {
      commercialFlagExtId: formData.commercialFlagExtId,
      personType: formData.personType,
      name:
        formData.personType === 2
          ? formData.businessName.trim().charAt(0).toUpperCase() +
            formData.businessName.slice(1)
          : formData.name.trim().charAt(0).toUpperCase() +
            formData.name.slice(1),
      surname:
        formData.surname.trim().charAt(0).toUpperCase() +
        formData.surname.slice(1),
      businessName:
        formData.businessName.trim().charAt(0).toUpperCase() +
        formData.businessName.slice(1),
      fiscalCode: formData.fiscalCode.toUpperCase().trim(),
      vat:
        formData.personType === 2
          ? formData.fiscalCode.toUpperCase().trim()
          : formData.vat.trim(),
      sdi: formData.sdi.trim(),
      username: formData.username.trim(),
      password: formData.password.trim(),
      iban: formData.iban.trim(),
      status: formData.status,
      permission: formData.permission,
      eula: formData.eula,
      privacy: formData.privacy,
      age: formData.eula,
      promo: formData.promo,
    },
    contacts: [
      {
        contact: formData.email.trim(),
        contactType: formData.emailType,
      },
      {
        contact: formData.telephone.trim(),
        contactType: formData.telephoneType,
      },
    ],
    personJobs: [
      {
        jobType: formData.jobType,
      },
    ],
    addresses: [
      {
        address: formData.address.trim(),
        number: formData.number,
        locality: formData.locality.trim(),
        zip: formData.zip.trim(),
        addressType: formData.personType === 0 ? 2 : 0,
      },
    ],
  };
}

export default SignUpCard;

import React from "react";
import { useParams, Navigate } from "react-router-dom";

import GradientBackground from "../../components/GradientBackround";
import SignUpCard from "../../components/Cards/SignUpCard";
import { hashUrlChecker } from "../../helpers/cryptoHelper";
import { Box } from "@mui/material";

const SignUpPage = () => {
  let { randomString, id } = useParams();
  const isRealId = hashUrlChecker(randomString, id);

  return (
    <GradientBackground>
      {isRealId ? (
        <SignUpCard commercialFlagExtId={id}></SignUpCard>
      ) : (
        <Navigate to="/logIn" />
      )}
    </GradientBackground>
  );
};

export default SignUpPage;

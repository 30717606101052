import React, { useState, useEffect } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/it";
import dayjs, { Dayjs } from "dayjs";
import standardRequestGenerator from "../../../helpers/httpHelperV1";
import { user, token, isAuth } from "../../../athoms/auth";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  Unstable_Grid2 as Grid,
  TextField,
  Button,
  Typography,
  Divider,
  Stack,
  InputAdornment,
  Box,
  Modal,
  Container,
  Select,
  MenuItem,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid",
  borderColor: "primary.main",
  boxShadow: 24,
  borderRadius: 3,
  pt: 2,
  px: 4,
  pb: 3,
};

const ModalNewRipartizione = ({ open, closeModal, personData }) => {
  const [okToSend, setOkToSend] = useState(false);
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);
  const [modifiedValues, setModifiedValues] = useState({});
  const [collectingNames, setCollectingNames] = useState([]);
  const variant = "outlined";

  useEffect(() => {
    if (!okToSend && isAuthState && Object.keys(personData ?? {}).length > 0) {
      setCollectingNames(personData.collecting.map((e) => e.ragioneSociale));
    }
    if (okToSend && isAuthState) {
      const postRipartizione = standardRequestGenerator({
        url: "auto/collectingJobs",
        queryType: "POST",
        token: tokenState,
        body: {
          ...modifiedValues,
          clienteId: personData._id,
          clienteNome: personData.nome,
          clienteCognome: personData.cognome,
          clienteRagioneSociale: personData.ragioneSociale,
        },
      });
      // console.log({
      //   ...modifiedValues,
      //   cliente: { ...personData },
      // });
      postRipartizione().then(() => closeModal());
    }
  }, [okToSend, isAuthState, personData]);

  const onSubmit = (e) => {
    e.preventDefault();
    setOkToSend(true);
  };

  const onChangeField = (e) => {
    setModifiedValues({
      ...modifiedValues,
      [e.target.name]: e.target.value.replaceAll(",", "."),
    });
    e.preventDefault();
  };

  const onChangeSelect = (e) => {
    setModifiedValues({
      ...modifiedValues,
      [e.target.name]: e.target.value,
      percentualeLaManu: extactManuPercentage(personData, e.target.value),
    });
    e.preventDefault();
  };

  const onChangeDate = (val) => {
    const date = dayjs(val);
    const timestamp = date.$d.getTime();
    setModifiedValues({
      ...modifiedValues,
      dataRipartizione: timestamp,
    });
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <form onSubmit={onSubmit}>
        <Box sx={{ ...style, width: "45vw", maxHeight: "70vh" }}>
          <Stack spacing={2} padding={1}>
            <Typography
              color={"primary.main"}
              id="parent-modal-title"
              sx={{
                fontSize: "1.2rem",
                fontWeight: 500,
                textTransform: personData?.CSSTextTransform?.visual ?? "",
              }}
            >
              {`Nuova ripartizione cliente ${personData?.cognome ?? ""} ${
                personData?.nome ?? ""
              }`}
            </Typography>
            <Divider />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                verticalAlign: "middle",
              }}
            >
              <Container>
                <Grid container spacing={2} justifyContent={"center"}>
                  <Grid item xs={3}>
                    <Select
                      required={true}
                      onChange={onChangeSelect}
                      size="small"
                      fullWidth={true}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={modifiedValues.collecting ?? ""}
                      name="collecting"
                      variant={variant}
                    >
                      {collectingNames.map((e) => (
                        <MenuItem key={e} value={e}>
                          <Typography
                            sx={{
                              textTransform: "capitalize",
                            }}
                          >
                            {e}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={4}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={"it"}
                    >
                      <MobileDateTimePicker
                        slotProps={{
                          textField: { size: "small", fullWidth: true },
                        }}
                        label="data Riparizione"
                        value={convertDate(modifiedValues.dataRipartizione)}
                        onChange={onChangeDate}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={2}>
                    {/* <TextField
                      value={modifiedValues?.protocollo ?? ""}
                      onChange={onChangeField}
                      fullWidth={true}
                      size="small"
                      type="text"
                      name="protocollo"
                      label="protocollo"
                      variant={variant}
                      inputProps={{
                        style: {
                          textTransform: "capitalize",
                        },
                      }}
                    ></TextField> */}
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      InputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography>%</Typography>
                          </InputAdornment>
                        ),
                      }}
                      value={modifiedValues.percentualeLaManu ?? ""}
                      onChange={onChangeField}
                      fullWidth={true}
                      size="small"
                      type="text"
                      name="percentualeLaManu"
                      label="%LaManu"
                      variant={variant}
                    ></TextField>
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      required={true}
                      InputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography>€</Typography>
                          </InputAdornment>
                        ),
                      }}
                      value={modifiedValues.importoLordo ?? ""}
                      onChange={onChangeField}
                      fullWidth={true}
                      size="small"
                      type="text"
                      name="importoLordo"
                      label="importo Lordo"
                      variant={variant}
                    ></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      required={true}
                      InputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography>€</Typography>
                          </InputAdornment>
                        ),
                      }}
                      value={modifiedValues.importoNetto ?? ""}
                      onChange={onChangeField}
                      fullWidth={true}
                      size="small"
                      type="text"
                      name="importoNetto"
                      label="importo Netto"
                      variant={variant}
                    ></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      InputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography>€</Typography>
                          </InputAdornment>
                        ),
                      }}
                      value={
                        modifiedValues.provvigioneLaManu ??
                        calculateLaManuCommission(modifiedValues)
                      }
                      onChange={onChangeField}
                      fullWidth={true}
                      size="small"
                      type="text"
                      name="provvigioneLaManu"
                      label="€ LaManu"
                      variant={variant}
                    ></TextField>
                  </Grid>
                </Grid>
              </Container>
            </Box>
            <Divider />
            <Stack direction={"row-reverse"} justifyItems={"flex-end"}>
              <Button
                type="submit"
                sx={{ color: "white" }}
                variant="contained"
                onSubmit={onSubmit}
              >
                {"INSERISCI"}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </form>
    </Modal>
  );
};

function convertDate(personDataDate, modifiedValuesDate) {
  if (personDataDate) {
    return dayjs(personDataDate);
  } else if (modifiedValuesDate) {
    return dayjs(modifiedValuesDate);
  } else {
    return undefined;
  }
}

function extactManuPercentage(personData, selectedCollecting) {
  const collectingObject = personData.collecting.filter(
    (e) => e.ragioneSociale === selectedCollecting
  )[0];

  return collectingObject?.percentualeConcordata ?? "";
}

function calculateLaManuCommission(modifiedValues) {
  const laManu =
    (parseFloat(modifiedValues.importoNetto) / 100) *
    parseFloat(modifiedValues.percentualeLaManu);
  return isNaN(laManu) ? "" : laManu.toFixed(2);
}

export default ModalNewRipartizione;

import { Container, SvgIcon } from "@mui/material";
import React from "react";

import FolderPlusIcon from "@heroicons/react/24/outline/FolderPlusIcon";

const NewAction = ({ onDelete }) => {
  return (
    <SvgIcon color="success" onClick={onDelete} sx={{ cursor: "pointer" }}>
      <FolderPlusIcon />
    </SvgIcon>
  );
};

export default NewAction;

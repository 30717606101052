import { Container, SvgIcon } from "@mui/material";
import React from "react";

import TrashIcon from "@heroicons/react/24/outline/TrashIcon";

const DeleteAction = ({ onDelete }) => {
  return (
    <SvgIcon color="secondary" onClick={onDelete} sx={{ cursor: "pointer" }}>
      <TrashIcon />
    </SvgIcon>
  );
};

export default DeleteAction;

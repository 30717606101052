import React, { useState } from "react";
import {
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  TextField,
  Paper,
  Typography,
  Checkbox,
  FormControlLabel,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormattedNumber } from "react-intl";

import standardRequestGenerator from "../../../helpers/httpHelperV1";

import { useRecoilState, useRecoilValue } from "recoil";

import { user, token, isAuth } from "../../../athoms/auth";
import DeleteAction from "../../IconActions/DeleteAction";
import UpdateAction from "../../IconActions/UpdateAction";

const ClientCollectingData = ({
  collectingData,
  collectingNames,
  setPersonData,
  personData,
  newRow,
  setNewRow,
}) => {
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);
  const [modifiedValues, setModifiedValues] = useState({});

  const [sent, setSent] = useState(false);
  const variant = "outlined";

  const onChangeField = (e) => {
    setModifiedValues({
      ...modifiedValues,
      [e.target.name]: e.target.value,
    });
    e.preventDefault();
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  const onUpdate = (e) => {
    const removeAddress = standardRequestGenerator({
      url: `auto/people/${personData._id}`,
      token: tokenState,
      isAuthState: setIsAuthState,
      queryType: "REMOVE",
      body: { collecting: [collectingData._id] },
    });
    const addAddress = standardRequestGenerator({
      url: `auto/people/${personData._id}`,
      token: tokenState,
      isAuthState: setIsAuthState,
      queryType: "ADD",
      body: {
        collecting: [
          {
            ragioneSociale: collectingData.ragioneSociale,
            percentualeConcordata: collectingData.percentualeConcordata,
            guadagnoTotale: collectingData.guadagnoTotale,
            user: collectingData.user,
            password: collectingData.password,
            pin: collectingData.pin,
            delegaLaManu: collectingData.delegaLaManu,
            worldWide: collectingData.worldWide,
            nazione: collectingData.nazione,
            eccezioni: collectingData.nazione,
            ...modifiedValues,
          },
        ],
      },
    });
    if (newRow) {
      addAddress()
        .then((e) => {
          setPersonData(e.body.rows[0]);
          setNewRow(false);
        })
        .catch((e) => alert("Compilare tutti i campi richiesti"));
    } else {
      removeAddress()
        .then(() => addAddress())
        .then((e) => setPersonData(e.body.rows[0]));
    }
  };

  const onDelete = (e) => {
    const removeAddress = standardRequestGenerator({
      url: `auto/people/${personData._id}`,
      token: tokenState,
      isAuthState: setIsAuthState,
      queryType: "REMOVE",
      body: { collecting: [collectingData._id] },
    });
    removeAddress().then(() => {
      setPersonData({
        ...personData,
        collecting: personData.collecting.filter(
          (e) => e._id !== collectingData._id
        ),
      });
    });
    e.preventDefault();
  };

  const changeDelegaValue = (e) => {
    setModifiedValues({
      ...modifiedValues,
      delegaLaManu: !(
        modifiedValues.delegaLaManu ?? collectingData.delegaLaManu
      ),
    });
    e.preventDefault();
  };

  const changeWorldWideValue = (e) => {
    setModifiedValues({
      ...modifiedValues,
      worldWide: !(modifiedValues.worldWide ?? collectingData.worldWide),
    });
    e.preventDefault();
  };

  return (
    <Paper
      elevation={0}
      sx={{
        boxShadow:
          "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
      }}
    >
      {" "}
      <Accordion
        sx={{
          boxShadow:
            "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
          borderRadius: 3,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{
              textTransform:
                collectingData.CSSTextTransform?.ragioneSociale ?? "",
            }}
          >
            {collectingData.ragioneSociale}
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <form autoComplete="off" noValidate onSubmit={onSubmit}>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item xs={4}>
                <Select
                  onChange={onChangeField}
                  size="small"
                  fullWidth={true}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    modifiedValues?.ragioneSociale ??
                    collectingData.ragioneSociale
                  }
                  name="ragioneSociale"
                  variant={variant}
                >
                  {collectingNames.map((e) => (
                    <MenuItem key={e} value={e}>
                      <Typography
                        sx={{
                          textTransform:
                            collectingData.CSSTextTransform?.ragioneSociale ??
                            "",
                        }}
                      >
                        {e}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  InputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography>%</Typography>
                      </InputAdornment>
                    ),
                  }}
                  value={
                    modifiedValues?.percentualeConcordata ??
                    collectingData?.percentualeConcordata ??
                    ""
                  }
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.contatto ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  size="small"
                  type="text"
                  name="percentualeConcordata"
                  label="%LaManu"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={5}>
                {/* <TextField
                  InputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography>€</Typography>
                      </InputAdornment>
                    ),
                  }}
                  value={
                    modifiedValues?.guadagnoTotale ??
                    collectingData?.guadagnoTotale ??
                    ""
                  }
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.contatto ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  size="small"
                  type="text"
                  name="guadagnoTotale"
                  label="guadagno Totale"
                  variant={variant}
                ></TextField> */}
              </Grid>
              <Grid item xs={4}>
                <TextField
                  inputProps={{
                    style: {
                      textTransform:
                        collectingData.CSSTextTransform?.user ?? "",
                    },
                  }}
                  value={modifiedValues?.user ?? collectingData?.user ?? ""}
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.user ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  size="small"
                  type="text"
                  name="user"
                  label="username"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  inputProps={{
                    style: {
                      textTransform:
                        collectingData.CSSTextTransform?.password ?? "",
                    },
                  }}
                  value={
                    modifiedValues?.password ?? collectingData?.password ?? ""
                  }
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.password ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  size="small"
                  type="text"
                  name="password"
                  label="password"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  inputProps={{
                    style: {
                      textTransform: collectingData.CSSTextTransform?.pin ?? "",
                    },
                  }}
                  value={modifiedValues?.pin ?? collectingData?.pin ?? ""}
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.pin ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  size="small"
                  type="text"
                  name="pin"
                  label="pin"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  inputProps={{
                    style: {
                      textTransform:
                        collectingData.CSSTextTransform?.nazione ?? "",
                    },
                  }}
                  value={
                    modifiedValues?.nazione ?? collectingData?.nazione ?? ""
                  }
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.user ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  size="small"
                  type="text"
                  name="nazione"
                  label="nazione"
                  variant={variant}
                ></TextField>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  inputProps={{
                    style: {
                      textTransform:
                        collectingData.CSSTextTransform?.eccezioni ?? "",
                    },
                  }}
                  value={
                    modifiedValues?.eccezioni ?? collectingData?.eccezioni ?? ""
                  }
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.user ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  size="small"
                  type="text"
                  name="eccezioni"
                  label="eccezioni"
                  variant={variant}
                ></TextField>
              </Grid>

              <Grid item xs={4}>
                <FormControlLabel
                  required
                  control={
                    <Checkbox
                      checked={
                        modifiedValues?.delegaLaManu ??
                        collectingData?.delegaLaManu
                      }
                      name={"delegaLaManu"}
                      onChange={changeDelegaValue}
                    />
                  }
                  label={<Typography variant="body2">Delega LaManu</Typography>}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  required
                  control={
                    <Checkbox
                      checked={
                        modifiedValues?.worldWide ?? collectingData?.worldWide
                      }
                      name={"worldWide"}
                      onChange={changeWorldWideValue}
                    />
                  }
                  label={<Typography variant="body2">WorldWide</Typography>}
                />
              </Grid>
              <Grid item xs={1}>
                {Object.keys(modifiedValues).length > 0 && !sent && (
                  <UpdateAction onUpdate={onUpdate} />
                )}
              </Grid>
              <Grid item xs={1}>
                <DeleteAction onDelete={onDelete} />
              </Grid>
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

export default ClientCollectingData;

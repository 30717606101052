import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import ThemeOptions from "./theme/ThemeOptions";

let theme = createTheme(ThemeOptions);
theme = responsiveFontSizes(theme);

const loadingMarkup = <div className="py-4 text-center">LOADING</div>;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense>
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </React.StrictMode>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

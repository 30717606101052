import React, { useRef } from "react";

import {
  Unstable_Grid2 as Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  Typography,
  Divider,
  Stack,
  useTheme,
} from "@mui/material";

const Step1 = ({ step, setStep, onChangeField, formData, variant }) => {
  const windowWidth = useRef(window.innerWidth);

  const SubmitHandler = (e) => {
    increaseStep();
    e.preventDefault();
  };

  const onClick = (e) => {
    alert("no handlers");
    //e.preventDefault();
  };

  const increaseStep = () => {
    setStep(step + 1);
  };

  return (
    <form onSubmit={SubmitHandler}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} textAlign={"center"}>
          <Typography variant="h6">DATI ANAGRAFICI</Typography>
        </Grid>

        <Grid item xs={12} md={12}>
          <Select
            onChange={onChangeField}
            fullWidth={windowWidth.current <= 900}
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formData.personType}
            name="personType"
          >
            {personTypeEnum().map((e, i) => (
              <MenuItem key={i} value={i}>
                {e}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={6} hidden={formData.personType === 0}>
          <TextField
            value={formData.businessName}
            onChange={onChangeField}
            required={formData.personType !== 0}
            fullWidth={true}
            color="primary"
            size="small"
            type="text"
            name="businessName"
            label="ragione sociale"
            variant={variant}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6} hidden={formData.personType === 2}>
          <TextField
            value={formData.name}
            onChange={onChangeField}
            fullWidth={true}
            required={formData.personType !== 2}
            color="primary"
            size="small"
            type="text"
            name="name"
            label="nome"
            variant={variant}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6} hidden={formData.personType === 2}>
          <TextField
            value={formData.surname}
            onChange={onChangeField}
            fullWidth={true}
            required={formData.personType !== 2}
            color="primary"
            size="small"
            type="text"
            name="surname"
            label="cognome"
            variant={variant}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            value={formData.fiscalCode}
            onChange={onChangeField}
            fullWidth={true}
            required
            color="primary"
            size="small"
            type="text"
            name="fiscalCode"
            label={
              formData.personType === 2
                ? "codice fiscale/p.iva"
                : "codice fiscale"
            }
            variant={variant}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6} hidden={formData.personType !== 1}>
          <TextField
            required={formData.personType === 1}
            value={formData.vat}
            onChange={onChangeField}
            fullWidth={true}
            color="primary"
            size="small"
            type="text"
            name="vat"
            label="p.iva"
            variant={variant}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={formData.personType == 2 ? 12 : 6}>
          <TextField
            value={formData.iban}
            onChange={onChangeField}
            fullWidth={true}
            color="primary"
            size="small"
            type="text"
            name="iban"
            label="iban"
            variant={variant}
          ></TextField>
        </Grid>
        <Grid item xs={12} xsOffset={10} mdOffset={11}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Button
              onSubmit={SubmitHandler}
              type="submit"
              size="large"
              variant="text"
              sx={{
                color: "primary.main",
                borderColor: "primary.main",
                marginRight: {
                  xs: "1%",
                },
                marginBottom: "10%",
              }}
            >
              {"avanti"}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

function personTypeEnum() {
  return ["Persona Fisica", "Ditta Individuale", "Società"];
}

export default Step1;

import React, { useState, useEffect } from "react";

import {
  Unstable_Grid2 as Grid,
  TextField,
  Button,
  Typography,
  Divider,
  Stack,
} from "@mui/material";

const Step2 = ({ step, setStep, onChangeField, formData, variant }) => {
  const SubmitHandler = (e) => {
    increaseStep();
    e.preventDefault();
  };

  const onClick = (e) => {
    alert("no handlers");
    //e.preventDefault();
  };

  const increaseStep = () => {
    setStep(step + 1);
  };

  const decreaseStep = () => {
    setStep(step - 1);
  };

  return (
    <form onSubmit={SubmitHandler}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} textAlign={"center"}>
          <Typography variant="h6">CONTATTI</Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            value={formData.email}
            onChange={onChangeField}
            required
            fullWidth={true}
            color="primary"
            size="small"
            type="email"
            name="email"
            label="email"
            variant={variant}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            value={formData.telephone}
            onChange={onChangeField}
            required
            fullWidth={true}
            color="primary"
            size="small"
            type="text"
            name="telephone"
            label="telefono"
            variant={variant}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={formData.address}
            onChange={onChangeField}
            required
            fullWidth={true}
            color="primary"
            size="small"
            type="text"
            name="address"
            label="indirizzo"
            variant={variant}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            value={formData.zip}
            onChange={onChangeField}
            required
            fullWidth={true}
            color="primary"
            size="small"
            type="text"
            name="zip"
            label="cap"
            variant={variant}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            value={formData.locality}
            onChange={onChangeField}
            required
            fullWidth={true}
            color="primary"
            size="small"
            type="text"
            name="locality"
            label="comune"
            variant={variant}
          ></TextField>
        </Grid>

        <Grid item xs={12}>
          <Stack direction={"row"} alignItems={"center"}>
            <Button
              onClick={decreaseStep}
              size="large"
              variant="text"
              sx={{
                color: "primary.main",
                borderColor: "primary.main",
                marginLeft: {
                  xs: "1%",
                },
                marginBottom: "2%",
                marginRight: "auto",
              }}
            >
              {"INDETRO"}
            </Button>
            <Button
              onSubmit={SubmitHandler}
              type="submit"
              size="large"
              variant="text"
              sx={{
                color: "primary.main",
                borderColor: "primary.main",
                marginRight: {
                  xs: "1%",
                },
                marginBottom: "2%",
                marginLeft: "auto",
              }}
            >
              {"avanti"}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default Step2;

import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import { isAuthSelector, isAuth, user, token } from "../../athoms/auth";
import secureLocalStorage from "react-secure-storage";

const ProtectedRoutes = ({ children }) => {
  const isAuthSelect = useRecoilValue(isAuthSelector);
  const [tokenState, setTokenState] = useRecoilState(token);
  const [userState, setUserState] = useRecoilState(user);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);

  useEffect(() => {
    if (isAuthSelect && !isAuthState) {
      const userBrowser = secureLocalStorage.getItem("user");
      const tokenBrowser = secureLocalStorage.getItem("token");
      if (userBrowser != null && tokenBrowser != null) {
        setTokenState(tokenBrowser);
        setUserState(userBrowser);
        setIsAuthState(true);
      }
    }
  }, [isAuthSelect]);

  return isAuthSelect ? children : <Navigate to="/logIn" />;
};
export default ProtectedRoutes;

// const logIn = postRequestGenerator({
//   url: "logIn",
//   queryType: "GET",
//   tokenState: tokenState,
//   body: {
//     username: "Administratorinius",
//     password: "Z!4n&rWb8W7",
//   },
//   setIsAuthState: setIsAuthState,
// });
// logIn()
//   .then((e) => console.log(e))
//   .catch((e) => alert(e));

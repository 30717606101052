import CryptoJS from "crypto-js";

const k = CryptoJS.enc.Hex.parse(process.env.REACT_APP_JAMES_C.toLowerCase());
const i = CryptoJS.enc.Hex.parse(process.env.REACT_APP_JAMES_I.toLowerCase());

const signUpUrlPass = process.env.REACT_APP_SIGNUP_URL;

const encrypt = (request) => {
  return CryptoJS.AES.encrypt(JSON.stringify(request), k, {
    iv: i,
    mode: CryptoJS.mode.CBC,
    //padding: CryptoJS.pad.ZeroPadding,
  }).toString();
};

const decrypt = (response) => {
  const plainRespone = CryptoJS.AES.decrypt(response, k, {
    iv: i,
    mode: CryptoJS.mode.CBC,
    //padding: CryptoJS.pad.ZeroPadding,
  }).toString(CryptoJS.enc.Utf8);
  return JSON.parse(plainRespone);
};

const hashUrl = (id) => {
  return CryptoJS.SHA3(`${id}${signUpUrlPass}`, {
    outputLength: 224,
  })
    .toString(CryptoJS.enc.Base64)
    .replaceAll("/", "-");
};

const hashUrlChecker = (hash, id) => {
  return (
    CryptoJS.SHA3(`${id}${signUpUrlPass}`, {
      outputLength: 224,
    })
      .toString(CryptoJS.enc.Base64)
      .replaceAll("/", "-") === hash
  );
};

export { encrypt, decrypt, hashUrl, hashUrlChecker };

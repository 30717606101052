import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import ProtectedRoutes from "./ProtectedRoute";
import LoginPage from "../../pages/LogIn";
import DashboardPage from "../../pages/Dashboard";
// import DownLinePage from "../../pages/DownLine";
// import Notizie from "../../pages/Notizie";
// import Guadagni from "../../pages/Guadagni";
import SignUpPage from "../../pages/SignUp";
// import ImpostazioniPage from "../../pages/Impostazioni";
import AnagraficaEdit from "../../pages/AnagraficaEdit";
import AnagraficaPage from "../../pages/Anagrafica";
import RipartizioniPage from "../../pages/Ripartizioni";
import GuadagniPage from "../../pages/Guadagni";
// import GestioneNotiziePage from "../../pages/Employee-Pages/GestioneNotizie";
// import GestioneNotizieEdit from "../../pages/Employee-Pages/GestioneNotizieEdit";
// import PagamentiPage from "../../pages/Employee-Pages/Pagamenti";
// const AnagraficaPage = React.lazy(() =>
//   import("../../pages/Employee-Pages/Anagrafica")
// );

const Error = () => {
  return <p>ERROR</p>;
};

const AllRoute = () => {
  return (
    <Router>
      <Routes>
        {/* PUBLIC */}
        <Route exact path="/logIn" element={<LoginPage />} />
        <Route
          exact
          path="/signUp/:randomString/:id"
          element={<SignUpPage />}
        />

        {/* PROTECTED */}
        <Route
          exact
          path="/"
          element={
            <ProtectedRoutes>
              <DashboardPage />
            </ProtectedRoutes>
          }
        />
        {/* <Route
          exact
          path="/notizie"
          element={
            <ProtectedRoutes>
              <Notizie />
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/downline"
          element={
            <ProtectedRoutes>
              <DownLinePage />
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/portafoglio"
          element={
            <ProtectedRoutes>
              <Guadagni />
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/impostazioni"
          element={
            <ProtectedRoutes>
              <ImpostazioniPage />
            </ProtectedRoutes>
          }
        /> */}
        {/* ADMIN AND EMPLOYEES */}
        {/* ANAGRAFICA */}
        <Route
          exact
          path="/anagrafica"
          element={
            <ProtectedRoutes>
              <AnagraficaPage />
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/anagrafica/:id"
          element={
            <ProtectedRoutes>
              <AnagraficaEdit />
            </ProtectedRoutes>
          }
        />
        {/* NOTIZIE */}
        <Route
          exact
          path="/ripartizioni"
          element={
            <ProtectedRoutes>
              <RipartizioniPage />
            </ProtectedRoutes>
          }
        />
         <Route
          exact
          path="/guadagni"
          element={
            <ProtectedRoutes>
              <GuadagniPage />
            </ProtectedRoutes>
          }
        />
        {/* <Route
          exact
          path="/gestione-notizie/:id"
          element={
            <ProtectedRoutes>
              <GestioneNotizieEdit />
            </ProtectedRoutes>
          }
        /> */}
        {/* PAGAMENTI */}
        {/* <Route
          exact
          path="/pagamenti"
          element={
            <ProtectedRoutes>
              <PagamentiPage />
            </ProtectedRoutes>
          }
        /> */}
        {/* ERROR 404 */}
        <Route path="/*" element={<Navigate to={"/"} />} />
      </Routes>
    </Router>
  );
};

export default AllRoute;

import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Unstable_Grid2 as Grid,
  Stack,
  useTheme,
  Typography,
} from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";

import { BasicPageLayout } from "../../layouts/BasicPageLayout";
import CardChildren from "../../components/Cards/CardChildren";
import CardNews from "../../components/Cards/CardNews";
import CardPersonalLevel from "../../components/Cards/CardPersonalLevel";
import CardNextLevel from "../../components/Cards/CardNextLevel";
import CardChildrenInProduction from "../../components/Cards/CardChildrenInProduction";
import CardPieChartLevel from "../../components/Cards/CardPieChartLevel";

import { getRequestGenerator } from "../../helpers/httpHelper";
import { user, token, isAuth } from "../../athoms/auth";
import secureLocalStorage from "react-secure-storage";
import CardInsertNews from "../../components/Cards/CardInsertNews";
import CardInsertChild from "../../components/Cards/CardInsertChild";

const DashboardPage = () => {
  const [personAggregate, setPersonAggregate] = useState({
    people: {},
    contacts: [],
    personalLevel: {},
    personJobs: [],
    addresses: [],
    personalLevelRules: [],
  });
  const userState = useRecoilValue(user);
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);
  const theme = useTheme();

  // useEffect(() => {
  //   if (isAuthState) {
  //     const getPersonAggregate = getRequestGenerator({
  //       url: `aggregated/people/${userState.id}`,
  //       queryType: "GET",
  //       tokenState: tokenState,
  //       isAuthState: setIsAuthState,
  //     });
  //     getPersonAggregate().then((e) => {
  //       setPersonAggregate({
  //         ...e.body.results[0],
  //         personalLevelRules: secureLocalStorage.getItem("personalLevelRules"),
  //       });
  //     });
  //   } else {
  //     return;
  //   }
  // }, [isAuthState]);

  return (
    <BasicPageLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8.5,
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid xs={12} md={12} lg={8}>
              <Typography>
                {" "}
                <Typography>CIAO LA MANU!</Typography>
              </Typography>
            </Grid>

            {/* <Grid xs={12} sm={6} lg={3}>
              <CardPersonalLevel
                personAggregate={personAggregate}
                sx={{
                  height: "100%",
                  boxShadow:
                    "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
                  borderRadius: 3,
                }}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={3}>
              <CardNews
                personAggregate={personAggregate}
                sx={{
                  height: "100%",
                  boxShadow:
                    "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
                  borderRadius: 3,
                }}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={3}>
              <CardChildren
                personAggregate={personAggregate}
                sx={{
                  height: "100%",
                  boxShadow:
                    "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
                  borderRadius: 3,
                }}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={3}>
              <CardChildrenInProduction
                personAggregate={personAggregate}
                sx={{
                  height: "100%",
                  boxShadow:
                    "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
                  borderRadius: 3,
                }}
              />
            </Grid>

            <Grid xs={12} lg={7}>
              <Box sx={{ height: "100%", margin: 0 }}>
                <Stack spacing={3} direction={"column"}>
                  <CardNextLevel
                    personAggregate={personAggregate}
                    sx={{
                      height: "100%",
                      boxShadow:
                        "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
                      borderRadius: 3,
                    }}
                  />
                  <Stack
                    spacing={3}
                    direction={"row"}
                    justifyContent="space-between"
                  >
                    <CardInsertNews
                      sx={{
                        height: "100%",
                        boxShadow:
                          "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
                        borderRadius: 3,
                        width: "50%",
                      }}
                    />

                    <CardInsertChild
                      personAggregate={personAggregate}
                      sx={{
                        height: "100%",
                        boxShadow:
                          "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
                        borderRadius: 3,
                        width: "50%",
                      }}
                    />
                  </Stack>
                </Stack>
              </Box>
            </Grid>
            <Grid xs={12} lg={5}>
              <Box sx={{ height: "100%", margin: 0 }}>
                <CardPieChartLevel
                  personAggregate={personAggregate}
                  labels={["Notizie", "Segnalatori", "Segnalatori Lv.1"]}
                  sx={{
                    height: "100%",
                    [theme.breakpoints.up("xl")]: {
                      height: "97%",
                    },
                    boxShadow:
                      "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
                    borderRadius: 3,
                  }}
                />
              </Box>
            </Grid>

            <Grid xs={12} md={12} lg={8}></Grid> */}
          </Grid>
          <Grid xs={12} md={12} lg={8}>
            <Typography>Vedremo poi cosa mettere qui!!!</Typography>
          </Grid>
        </Container>
      </Box>
    </BasicPageLayout>
  );
};

export default DashboardPage;

import { RecoilRoot } from "recoil";
import { IntlProvider } from "react-intl";

import "./App.css";
import AllRoute from "./components/Router";

function App() {
  return (
    <IntlProvider messages={{}} locale="it" defaultLocale="it">
      <RecoilRoot>
        <AllRoute />
      </RecoilRoot>
    </IntlProvider>
  );
}

export default App;

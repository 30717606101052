import { atom, selector } from "recoil";
import secureLocalStorage from "react-secure-storage";

const user = atom({
  key: "user",
  default: {},
});

const token = atom({
  key: "token",
  default: "",
});

const isAuth = atom({
  key: "isAuth",
  default: false,
});

const isAuthSelector = selector({
  key: "isAuthSelector",
  default: false,
  get: ({ get }) => {
    return authenticationChecker(get);
  },
});

function authenticationChecker(get) {
  const authInState = get(isAuth);
  const userInState = Object.keys(get(user)).length > 0;
  const userInBrowser =
    Object.keys(secureLocalStorage.getItem("user") ?? {}).length > 0;
  const tokenInState = get(token).length > 171;
  const tokenInBrowser = secureLocalStorage.getItem("token") ?? "".length > 171;
  if (authInState && userInState && tokenInState) {
    return true;
  } else if (userInBrowser && tokenInBrowser) {
    return true;
  } else {
    secureLocalStorage.clear();
    return false;
  }
}

export { user, token, isAuth, isAuthSelector };

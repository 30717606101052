import ky from "ky";
import secureLocalStorage from "react-secure-storage";

import baseUrl from "./config";
import { encrypt, decrypt } from "./cryptoHelper";

const apiClient = ky.create({
  prefixUrl: baseUrl, // <- ENV variable
  //prefixUrl: "",
});

const { post } = apiClient;

const standardRequestGenerator = ({
  token,
  queryType,
  page,
  pagingSize,
  genericAndFilters,
  genericOrFilters,
  gt,
  lt,
  join,
  relations,
  orderBy,
  orderRule,
  additionalFields,
  body,
  isAuthState,
  url,
} = {}) => {
  const plainRequest = {
    header: {
      token: token ?? "",
      queryType: queryType ?? "Standard",
      errorCode: 0,
      errorMessage: "Success",
      pagingSize: pagingSize ?? 20000,
      page: page ?? 0,
      genericAndFilters: genericAndFilters ?? [],
      genericOrFilters: genericOrFilters ?? [],
      gt: gt ?? {},
      lt: lt ?? {},
      join: join ?? false,
      relations: relations ?? [],
      orderBy: orderBy ?? "",
      orderRule: orderRule ?? "",
      additionalFields: additionalFields ?? [],
      filters: {},
    },
    body: body ?? {},
  };
  return async () => {
    // const encryptedResponse = await post(url, { body: encrypt(plainRequest) });
    // return decrypt(await encryptedResponse.text());
    const encryptedResponse = await post(url, {
      body: JSON.stringify(plainRequest),
      headers: {
        "content-type": "text/plain",
      },
    });
    const res = JSON.parse(await encryptedResponse.text());
    if (res.header.errorCode === 401) {
      secureLocalStorage.clear();
      isAuthState(false);
    }
    if (res.header.errorCode === 403) {
      alert("Operazione non permessa");
    }
    if (res.header.errorCode > 0 && res.header.errorCode !== 403) {
      throw new Error(
        `ERRORE! Codice: ${res.header.errorCode} Messaggio: ${res.header.errorMessage}`
      );
    }
    return res;
  };
};

export default standardRequestGenerator;

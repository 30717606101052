import React, { useMemo, useState } from "react";
import {
  Typography,
  CardActions,
  CardContent,
  CardHeader,
} from "@mui/material";
import {
  DataGrid,
  itIT,
  GridToolbar,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { FormattedDate, FormattedNumber } from "react-intl";
import { useRecoilState, useRecoilValue } from "recoil";

import { user, token, isAuth } from "../../../athoms/auth";

const GuadagniRipartizioniTable = ({ collectingJobsData }) => {
  const [paginationState, setPaginationState] = useState(10);
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);

  const recordsFromApi = collectingJobsData.map((e) => {
    return {
      id: e._id,
      collecting: e.collecting,
      protocollo: e.protocollo,
      dataRipartizione: e.dataRipartizione,
      guadagno: e.guadagno,
    };
  });

  const columns = useMemo(
    () => [
      {
        field: "collecting",
        headerName: "COLLECTING",
        width: 200,
        renderCell: (e) => (
          <Typography variant={"body"} sx={{ textTransform: "capitalize" }}>
            {e.value}
          </Typography>
        ),
      },
      {
        field: "dataRipartizione",
        headerName: "DATA",
        width: 200,
        renderCell: (e) => <FormattedDate value={e.value} timeZone="Europe/Rome"/>,
      },
      {
        field: "protocollo",
        headerName: "PROTOCOLLO",
        width: 200,
        renderCell: (e) => (
          <Typography variant={"body"} sx={{ textTransform: "uppercase" }}>
            {e.value}
          </Typography>
        ),
      },

      {
        field: "guadagno",
        headerName: "TOTALE",
        width: 400,
        renderCell: (e) => (
          <FormattedNumber
            value={e.value.toString()}
            currency="Eur"
            style="currency"
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
        ),
      },
    ],
    []
  );

  return (
    <DataGrid
      showCellVerticalBorder={false}
      localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
      columns={columns}
      rows={recordsFromApi}
      getRowId={(row) => row.id}
      initialState={{
        ...recordsFromApi,
        pagination: {
          paginationModel: { pageSize: paginationState, page: 0 },
        },
      }}
      pageSizeOptions={[5, 10, 20, 50]}
      sx={{
        boxShadow:
          "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
        borderRadius: 3,
        border: 0,
      }}
      // onRowDoubleClick={onCLick}
      slots={{
        toolbar: () => <GridToolbar sx={{ mb: 2 }} />,
      }}
    />
  );
};

export default GuadagniRipartizioniTable;

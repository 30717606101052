import { Container, SvgIcon } from "@mui/material";
import React from "react";

import ClipboardDocumentCheckIcon from "@heroicons/react/24/outline/ClipboardDocumentCheckIcon";

const UpdateAction = ({ onUpdate }) => {
  return (
    <SvgIcon color="success" onClick={onUpdate} sx={{ cursor: "pointer" }}>
      <ClipboardDocumentCheckIcon />
    </SvgIcon>
  );
};

export default UpdateAction;

import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Unstable_Grid2 as Grid,
  Stack,
  useTheme,
  Tab,
  Tabs,
} from "@mui/material";

import { useRecoilState, useRecoilValue } from "recoil";

import { user, token, isAuth } from "../../athoms/auth";
import standardRequestGenerator from "../../helpers/httpHelperV1";

import { BasicPageLayout } from "../../layouts/BasicPageLayout";
import NuoveRipartizioniTable from "../../components/Tables/NuoveRipartizioniTable";
import FatturabiliRipartizioniTable from "../../components/Tables/FatturabiliRipartizioniTable";
import DaIncassareTable from "../../components/Tables/DaIncassareTable";
import ConcluseTable from "../../components/Tables/ConcluseTable";

const RipartizioniPage = () => {
  // const userState = useRecoilValue(user);
  const [status, setStatus] = useState("inLavorazione");
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);
  const theme = useTheme();

  const [collectingJobs, setCollectingJobs] = useState([]);

  const handleChange = (e, newValue) => {
    e.preventDefault();
    setStatus(newValue);
  };

  useEffect(() => {
    if (isAuthState) {
      if (status === "conclusa") {
        const getRipartizioniApiV1 = standardRequestGenerator({
          url: "auto/collectingJobsArchive",
          queryType: "GET",
          join: true,
          relations: ["clienteId"],
          token: tokenState,
          isAuthState: setIsAuthState,
        });
        getRipartizioniApiV1().then((e) => setCollectingJobs(e.body.rows));
      } else {
        const getRipartizioniApiV1 = standardRequestGenerator({
          url: "auto/collectingJobs",
          queryType: "GET",
          genericAndFilters: [{ stato: status }],
          join: true,
          relations: ["clienteId"],
          token: tokenState,
          isAuthState: setIsAuthState,
        });
        getRipartizioniApiV1().then((e) => setCollectingJobs(e.body.rows));
      }
    } else {
      return;
    }
  }, [isAuthState, status, collectingJobs]);

  return (
    <BasicPageLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8.5,
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid xs={12}>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <Tabs
                  value={status}
                  onChange={handleChange}
                  textColor="primary"
                  indicatorColor="primary"
                  aria-label="secondary tabs example"
                >
                  <Tab value={"inLavorazione"} label={"In lavorazione"} />

                  <Tab value={"fatturabile"} label={"Fatturabili"} />
                  <Tab value={"daIncassare"} label={"Da incassare"} />
                  <Tab value={"conclusa"} label={"Concluse"} />
                </Tabs>
              </Box>
            </Grid>

            <Grid xs={12}>
              {status === "inLavorazione" && (
                <NuoveRipartizioniTable
                  collectingJobsData={collectingJobs}
                  setCollectingJobsData={setCollectingJobs}
                />
              )}
              {status === "fatturabile" && (
                <FatturabiliRipartizioniTable
                  collectingJobsData={collectingJobs}
                  setCollectingJobsData={setCollectingJobs}
                />
              )}
              {status === "daIncassare" && (
                <DaIncassareTable
                  collectingJobsData={collectingJobs}
                  setCollectingJobsData={setCollectingJobs}
                />
              )}
              {status === "conclusa" && (
                <ConcluseTable
                  collectingJobsData={collectingJobs}
                  setCollectingJobsData={setCollectingJobs}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </BasicPageLayout>
  );
};

export default RipartizioniPage;

import { Container, SvgIcon } from "@mui/material";
import React from "react";

import CurrencyEuroIcon from "@heroicons/react/24/outline/CurrencyEuroIcon";

const CurrencyAction = ({ onUpdate }) => {
  return (
    <SvgIcon color="success" onClick={onUpdate} sx={{ cursor: "pointer" }}>
      <CurrencyEuroIcon />
    </SvgIcon>
  );
};

export default CurrencyAction;

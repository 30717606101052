import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Unstable_Grid2 as Grid,
  Button,
  useTheme,
  Tab,
  Tabs,
} from "@mui/material";

import { useRecoilState, useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";

import { user, token, isAuth } from "../../athoms/auth";
import standardRequestGenerator from "../../helpers/httpHelperV1";

import { BasicPageLayout } from "../../layouts/BasicPageLayout";
import Div6AnagraficaTable from "../../components/Tables/Div6AnagraficaTable";

const AnagraficaPage = () => {
  // const userState = useRecoilValue(user);
  const [division, setDivision] = useState("div2");
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);
  const [peopleData, setPeopleData] = useState([]);
  const theme = useTheme();
  const navigate = useNavigate();

  const handleChange = (e, newValue) => {
    e.preventDefault();
    setDivision(newValue);
  };

  useEffect(() => {
    if (isAuthState) {
      const getPeopleApiV1 = standardRequestGenerator({
        url: "auto/people",
        queryType: "GET",
        genericAndFilters: [{ tipoArchivio: division }],
        orderBy: "cognome",
        orderRule: "asc",
        token: tokenState,
        isAuthState: setIsAuthState,
      });
      getPeopleApiV1().then((e) => setPeopleData(e.body.rows));
    } else {
      return;
    }
  }, [division, isAuthState, peopleData]);

  return (
    <BasicPageLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8.5,
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid xs={12}>
              <Button
                onClick={() => navigate("/anagrafica/nuovo")}
                disabled={false}
                size="medium"
                variant="contained"
                sx={{
                  marginRight: { lg: "auto" },
                  mb: 3,
                  color: "white",
                }}
              >
                NUOVA ANAGRAFICA
              </Button>
            </Grid>
            <Grid xs={12}>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <Tabs
                  value={division}
                  onChange={handleChange}
                  textColor="primary"
                  indicatorColor="primary"
                  aria-label="secondary tabs example"
                >
                  <Tab value={"div2"} label={"Divisione 2"} />
                  <Tab value={"div6"} label={"Divisione 6"} />
                </Tabs>
              </Box>
            </Grid>
            <Grid xs={12}>
              <Div6AnagraficaTable
                personData={peopleData}
                setPeopleData={setPeopleData}
                division={division}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </BasicPageLayout>
  );
};

export default AnagraficaPage;

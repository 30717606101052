import React, { useMemo, useState, useEffect } from "react";
import {
  Typography,
  CardActions,
  CardContent,
  CardHeader,
} from "@mui/material";
import {
  DataGrid,
  itIT,
  GridToolbar,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { useRecoilState, useRecoilValue } from "recoil";
import { FormattedDate, FormattedNumber } from "react-intl";
import DeleteAction from "../../IconActions/DeleteAction";
import NewAction from "../../IconActions/NewAction";
import ModalNewRipartizione from "../../Modals/ModalNewRipartizione.js";

import { useNavigate } from "react-router-dom";
import { user, token, isAuth } from "../../../athoms/auth";
import standardRequestGenerator from "../../../helpers/httpHelperV1";

const Div6AnagraficaTable = ({ personData, setPeopleData, division }) => {
  const [paginationState, setPaginationState] = useState(10);
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);
  const [open, setOpen] = useState({ open: false, personData: {} });
  const navigate = useNavigate();

  const onCLick = (data, e) => {
    e.preventDefault();
    navigate(`/anagrafica/${data.id}`, {
      state: data.row.fullObject,
    });
  };

  const onDelete = (params) => {
    return () => {
      const deleteAnagraficaApi = standardRequestGenerator({
        url: `auto/people/${params.id}`,
        queryType: "DELETE",
        token: tokenState,
        isAuthState: setIsAuthState,
        body: {},
      });
      deleteAnagraficaApi().then(() =>
        setPeopleData(
          personData.filter(
            (e) => e._id !== params.id && e.TipoArchivio === division
          )
        )
      );
    };
  };

  const onAdd = (params) => {
    return () => {
      setOpen({
        open: true,
        personData: params.row.fullObject,
      });
    };
  };

  const closeModal = () => setOpen({ open: false });

  const recordsFromApi = personData.map((e) => {
    return {
      id: e._id,
      // visual: { value: generateVisual(e), css: e.CSSTextTransform.visual },
      visual: `${generateVisual(e)} ***${e.CSSTextTransform.visual}`,
      // settore: { value: e.settore, css: e.CSSTextTransform.settore },
      settore: `${e.settore} ***${e.CSSTextTransform.settore}`,
      // ruolo: { value: e.ruolo, css: e.CSSTextTransform.ruolo },
      ruolo: `${e.ruolo} ***${e.CSSTextTransform.ruolo}`,
      band: `${e.band} ***${e.CSSTextTransform.band}`,
      fsm: `${e.fsm} ***${e.CSSTextTransform.fsm}`,
      dataInizioCollaborazione: e.dataInizioCollaborazione,
      dataFineCollaborazione: e.dataFineCollaborazione,
      email: e.recapiti.filter((e) => e.tipo === "email")[0]?.contatto ?? "",
      cellulare:
        e.recapiti.filter(
          (e) => e.tipo === "cellulare" || e.tipo === "fisso"
        )[0]?.contatto ?? "",
      fullObject: e,
    };
  });

  const columns = useMemo(
    () => [
      {
        field: "visual",
        headerName: "NOME",
        width: 200,
        renderCell: (e) => {
          const values = e.value.split(" ***");
          return (
            <Typography variant={"body"} sx={{ textTransform: values[1] }}>
              {values[0]}
            </Typography>
          );
        },
      },

      {
        field: "settore",
        headerName: "SETTORE",
        width: 140,
        renderCell: (e) => {
          const values = e.value.split(" ***");
          return (
            <Typography variant={"body"} sx={{ textTransform: values[1] }}>
              {values[0]}
            </Typography>
          );
        },
      },
      {
        field: "ruolo",
        headerName: "RUOLO",
        width: 140,
        renderCell: (e) => {
          const values = e.value.split(" ***");
          return (
            <Typography variant={"body"} sx={{ textTransform: values[1] }}>
              {values[0]}
            </Typography>
          );
        },
      },
      {
        field: "band",
        headerName: "BAND",
        width: 190,
        renderCell: (e) => {
          const values = e.value.split(" ***");
          return (
            <Typography variant={"body"} sx={{ textTransform: values[1] }}>
              {values[0] === "undefined" ? "" : values[0]}
            </Typography>
          );
        },
      },
      {
        field: "cellulare",
        headerName: "TELEFONO",
        width: 140,
      },
      {
        field: "email",
        headerName: "EMAIL",
        width: 250,
      },
      {
        field: "fsm",
        headerName: "FSM",
        width: 190,
        renderCell: (e) => {
          const values = e.value.split(" ***");
          return (
            <Typography variant={"body"} sx={{ textTransform: values[1] }}>
              {values[0] === "undefined" ? "" : values[0]}
            </Typography>
          );
        },
      },
      {
        field: "dataInizioCollaborazione",
        headerName: "INIZIO COLL.",
        width: 120,
        renderCell: (e) =>
          e.value ? (
            <FormattedDate value={e.value} timeZone="Europe/Rome" />
          ) : (
            <Typography />
          ),
      },
      {
        field: "dataFineCollaborazione",
        headerName: "FINE COLL.",
        width: 120,
        renderCell: (e) =>
          e.value ? (
            <FormattedDate value={e.value} timeZone="Europe/Rome" />
          ) : (
            <Typography />
          ),
      },
      {
        field: "delete",
        type: "actions",
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={<NewAction />}
              onClick={onAdd(params)}
              label="pay"
            />,
            <GridActionsCellItem
              icon={<DeleteAction />}
              onClick={onDelete(params)}
              label="pay"
            />,
          ];
        },
      },
    ],
    []
  );

  return (
    <>
      <DataGrid
        showCellVerticalBorder={false}
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
        rows={recordsFromApi}
        getRowId={(row) => row.id}
        initialState={{
          ...recordsFromApi,
          pagination: {
            paginationModel: { pageSize: paginationState, page: 0 },
          },
        }}
        pageSizeOptions={[5, 10, 20, 50]}
        sx={{
          boxShadow:
            "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
          borderRadius: 3,
          border: 0,
        }}
        onRowDoubleClick={onCLick}
        slots={{
          toolbar: () => <GridToolbar sx={{ mb: 2 }} />,
        }}
      />
      {open.open && (
        <ModalNewRipartizione
          open={open.open}
          closeModal={closeModal}
          personData={open.personData}
        />
      )}
    </>
  );
};

function generateVisual(e) {
  return `${e.cognome} ${e.nome}`;
}

export default Div6AnagraficaTable;

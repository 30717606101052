import PropTypes from "prop-types";
import {
  Box,
  Divider,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from "@mui/material";
import secureLocalStorage from "react-secure-storage";
import { isAuthSelector, isAuth, user, token } from "../../athoms/auth";
import { useRecoilState, useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open, visual } = props;
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);
  const [userState, setUserState] = useRecoilState(user);
  const [tokenState, setTokenState] = useRecoilState(token);
  const isAuthSelectorState = useRecoilValue(isAuthSelector);
  const navigate = useNavigate();

  const handleSignOut = (e) => {
    if (isAuthSelectorState) {
      setIsAuthState(false);
      setUserState({});
      setTokenState("");
      secureLocalStorage.clear();
      navigate("/logIn");
      e.preventDefault();
    } else {
      e.preventDefault();
      return;
    }
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 200 } }}
    >
      <Box
        sx={{
          py: 1.5,
          px: 2,
        }}
      >
        <Typography variant="overline">Utente</Typography>
        <Typography color="text.secondary" variant="body2">
          {visual}
        </Typography>
      </Box>
      <Divider />
      <MenuList
        disablePadding
        dense
        sx={{
          p: "8px",
          "& > *": {
            borderRadius: 1,
          },
        }}
      >
        <MenuItem onClick={handleSignOut}>Logout</MenuItem>
      </MenuList>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

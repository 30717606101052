import { Container, SvgIcon } from "@mui/material";
import React from "react";

import BanknotesIcon from "@heroicons/react/24/outline/BanknotesIcon";

const GiAction = ({ onUpdate }) => {
  return (
    <SvgIcon color="success" onClick={onUpdate} sx={{ cursor: "pointer" }}>
      <BanknotesIcon />
    </SvgIcon>
  );
};

export default GiAction;

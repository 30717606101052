import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Select,
  MenuItem,
  Typography,
  Checkbox,
  FormControlLabel,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/it";
import dayjs, { Dayjs } from "dayjs";

import { postRequestGenerator } from "../../../helpers/httpHelper";
import standardRequestGenerator from "../../../helpers/httpHelperV1";
import { useRecoilState, useRecoilValue } from "recoil";
import { user, token, isAuth } from "../../../athoms/auth";
import { useNavigate } from "react-router-dom";

const CardPersonDetails = ({ personData, isNew, setPersonData }) => {
  const [modifiedValues, setModifiedValues] = useState({});
  const [personType, setPersonType] = useState([]);
  const [archiveType, setArchiveType] = useState([]);
  const [sector, setSector] = useState([]);
  const [sent, setSent] = useState(false);
  const variant = "outlined";
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);
  const navigate = useNavigate();

  const isNotPhysicalPerson = checkTipoPersona(personData, modifiedValues);

  const onChangeField = (e) => {
    setModifiedValues({
      ...modifiedValues,
      [e.target.name]: e.target.value,
    });
    if (sent) {
      setSent(false);
    }
    e.preventDefault();
  };

  const onChangeDateInizioCollaborazione = (val) => {
    const date = dayjs(val);
    const timestamp = date.$d.getTime();
    setModifiedValues({
      ...modifiedValues,
      dataInizioCollaborazione: timestamp,
    });
    if (sent) {
      setSent(false);
    }
  };

  const onChangeDateFineCollaborazione = (val) => {
    const date = dayjs(val);
    const timestamp = date.$d.getTime();
    setModifiedValues({
      ...modifiedValues,
      dataFineCollaborazione: timestamp,
    });
    if (sent) {
      setSent(false);
    }
  };

  useEffect(() => {
    if (isAuthState) {
      const getPersonType = standardRequestGenerator({
        token: tokenState,
        url: "auto/people",
        queryType: "LIST",
        additionalFields: ["tipoPersona"],
        isAuthState: setIsAuthState,
      });
      const archiveType = standardRequestGenerator({
        token: tokenState,
        url: "auto/people",
        queryType: "LIST",
        additionalFields: ["tipoArchivio"],
        isAuthState: setIsAuthState,
      });
      const sector = standardRequestGenerator({
        token: tokenState,
        url: "auto/people",
        queryType: "LIST",
        additionalFields: ["settore"],
        isAuthState: setIsAuthState,
      });
      Promise.all([getPersonType(), archiveType(), sector()]).then(
        ([personType, archive, secot]) => {
          setPersonType(personType.body.rows);
          setArchiveType(archive.body.rows);
          setSector(secot.body.rows);
        }
      );
    }
  }, [isAuthState]);

  const onSubmit = (e) => {
    if (isNew) {
      const postPerson = standardRequestGenerator({
        token: tokenState,
        url: `auto/people`,
        queryType: "POST",
        isAuthState: setIsAuthState,
        body: { ...modifiedValues },
      });

      postPerson()
        .then((e) => {
          setSent(true);
          setPersonData(e.body.rows[0]);
          navigate(`/anagrafica/${e.body.rows[0]._id}`, {
            state: e.body.rows[0],
          });
        })
        .catch((e) => console.log(e));
    } else {
      const patchPerson = standardRequestGenerator({
        token: tokenState,
        url: `auto/people/${personData._id}`,
        queryType: "PATCH",
        isAuthState: setIsAuthState,
        body: { ...modifiedValues },
      });
      patchPerson()
        .then(() => setSent(true))
        .catch((e) => console.log(e));
    }

    e.preventDefault();
  };

  return (
    <Card
      sx={{
        boxShadow:
          "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
        borderRadius: 3,
      }}
    >
      <form autoComplete="off" noValidate onSubmit={onSubmit}>
        <CardHeader
          subheader={`Ultima modifica: ${formatDate(personData.updatedAt)}`}
          title={"Dati personali"}
          titleTypographyProps={{
            variant: "overline",
            fontSize: "1.15rem",
            color: "#424242",
          }}
          subheaderTypographyProps={{
            variant: "caption",
            fontSize: ".75rem",
            color: "#616161",
            mt: "-.55rem",
            mb: ".0rem",
          }}
        />

        <CardContent sx={{ pt: 0 }}>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Select
                  color={
                    (modifiedValues?.tipoArchivio?.length ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  focused={
                    (modifiedValues?.tipoArchivio?.length ?? "") > 0 && sent
                  }
                  onChange={onChangeField}
                  size="small"
                  fullWidth={true}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    modifiedValues?.tipoArchivio ?? personData.tipoArchivio
                  }
                  name="tipoArchivio"
                  variant={variant}
                >
                  {archiveType.map((e) => (
                    <MenuItem key={e} value={e}>
                      <Typography
                        sx={{
                          textTransform:
                            personData.CSSTextTransform?.tipoArchivio ?? "",
                        }}
                      >
                        {e}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={modifiedValues?.fsm ?? personData.fsm ?? ""}
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.fsm?.length ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  focused={(modifiedValues?.fsm?.length ?? "") > 0 && sent}
                  size="small"
                  type="text"
                  name="fsm"
                  label="fsm"
                  variant={variant}
                  inputProps={{
                    style: {
                      textTransform: personData.CSSTextTransform?.fsm ?? "",
                    },
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"it"}
                >
                  <MobileDateTimePicker
                    slotProps={{
                      textField: { size: "small", fullWidth: true },
                    }}
                    label="Inizio collaborazione"
                    value={convertDate(
                      personData.dataInizioCollaborazione,
                      modifiedValues.dataInizioCollaborazione
                    )}
                    onChange={onChangeDateInizioCollaborazione}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"it"}
                >
                  <MobileDateTimePicker
                    slotProps={{
                      textField: { size: "small", fullWidth: true },
                    }}
                    size={"small"}
                    label="Fine collaborazione"
                    value={convertDate(
                      personData.dataFineCollaborazione,
                      modifiedValues.dataFineCollaborazione
                    )}
                    onChange={onChangeDateFineCollaborazione}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={2}>
                <Select
                  color={
                    (modifiedValues?.tipoPersona?.length ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  focused={
                    (modifiedValues?.tipoPersona?.length ?? "") > 0 && sent
                  }
                  onChange={onChangeField}
                  size="small"
                  fullWidth={true}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={modifiedValues?.tipoPersona ?? personData.tipoPersona}
                  name="tipoPersona"
                  variant={variant}
                >
                  {personType.map((e) => (
                    <MenuItem key={e} value={e}>
                      <Typography
                        sx={{
                          textTransform:
                            personData.CSSTextTransform?.tipoPersona ?? "",
                        }}
                      >
                        {e}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={2}>
                <Select
                  color={
                    (modifiedValues?.settore?.length ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  focused={(modifiedValues?.settore?.length ?? "") > 0 && sent}
                  onChange={onChangeField}
                  size="small"
                  fullWidth={true}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={modifiedValues?.settore ?? personData.settore}
                  name="settore"
                  variant={variant}
                >
                  {sector.map((e) => (
                    <MenuItem key={e} value={e}>
                      <Typography
                        sx={{
                          textTransform:
                            personData.CSSTextTransform?.settore ?? "",
                        }}
                      >
                        {e}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={4}>
                <TextField
                  value={modifiedValues?.ruolo ?? personData.ruolo ?? ""}
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.ruolo?.length ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  focused={(modifiedValues?.ruolo?.length ?? "") > 0 && sent}
                  size="small"
                  type="text"
                  name="ruolo"
                  label="ruoli"
                  variant={variant}
                  inputProps={{
                    style: {
                      textTransform: personData.CSSTextTransform?.ruolo ?? "",
                    },
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={modifiedValues?.band ?? personData.band ?? ""}
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.band?.length ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  focused={(modifiedValues?.band?.length ?? "") > 0 && sent}
                  size="small"
                  type="text"
                  name="band"
                  label="band"
                  variant={variant}
                  inputProps={{
                    style: {
                      textTransform: personData.CSSTextTransform?.band ?? "",
                    },
                  }}
                ></TextField>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={modifiedValues?.nome ?? personData.nome ?? ""}
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.nome?.length ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  focused={(modifiedValues?.nome?.length ?? "") > 0 && sent}
                  size="small"
                  type="text"
                  name="nome"
                  label="nome"
                  variant={variant}
                  inputProps={{
                    style: {
                      textTransform: personData.CSSTextTransform?.nome ?? "",
                    },
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={modifiedValues?.cognome ?? personData.cognome ?? ""}
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.cognome?.length ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  focused={(modifiedValues?.cognome?.length ?? "") > 0 && sent}
                  size="small"
                  type="text"
                  name="cognome"
                  label="cognome"
                  variant={variant}
                  inputProps={{
                    style: {
                      textTransform: personData.CSSTextTransform?.cognome ?? "",
                    },
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={
                    modifiedValues?.pseudonimo ?? personData.pseudonimo ?? ""
                  }
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.pseudonimo?.length ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  focused={
                    (modifiedValues?.pseudonimo?.length ?? "") > 0 && sent
                  }
                  size="small"
                  type="text"
                  name="pseudonimo"
                  label="pseudonimo"
                  variant={variant}
                  inputProps={{
                    style: {
                      textTransform:
                        personData.CSSTextTransform?.pseudonimo ?? "",
                    },
                  }}
                ></TextField>
              </Grid>

              {isNotPhysicalPerson && (
                <Grid item xs={4}>
                  {" "}
                  <TextField
                    value={
                      modifiedValues?.ragioneSociale ??
                      personData.ragioneSociale ??
                      ""
                    }
                    onChange={onChangeField}
                    fullWidth={true}
                    color={
                      (modifiedValues?.ragioneSociale?.length ?? "") > 0 && sent
                        ? "success"
                        : "primary"
                    }
                    focused={
                      (modifiedValues?.ragioneSociale?.length ?? "") > 0 && sent
                    }
                    size="small"
                    type="text"
                    name="ragioneSociale"
                    label="ragione sociale"
                    variant={variant}
                    inputProps={{
                      style: {
                        textTransform:
                          personData.CSSTextTransform?.ragioneSociale ?? "",
                      },
                    }}
                  ></TextField>{" "}
                </Grid>
              )}

              <Grid item xs={4}>
                <TextField
                  value={
                    modifiedValues?.codiceFiscale ??
                    personData.codiceFiscale ??
                    ""
                  }
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.codiceFiscale?.length ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  focused={
                    (modifiedValues?.codiceFiscale?.length ?? "") > 0 && sent
                  }
                  size="small"
                  type="text"
                  name="codiceFiscale"
                  label={
                    personData.personType === "persona giuridica"
                      ? "codice fiscale/p.iva"
                      : "codice fiscale"
                  }
                  variant={variant}
                  inputProps={{
                    style: {
                      textTransform:
                        personData.CSSTextTransform?.codiceFiscale ?? "",
                    },
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={4}>
                {isNotPhysicalPerson && (
                  <TextField
                    value={
                      modifiedValues?.partitaIva ?? personData.partitaIva ?? ""
                    }
                    onChange={onChangeField}
                    fullWidth={true}
                    color={
                      (modifiedValues?.partitaIva?.length ?? "") > 0 && sent
                        ? "success"
                        : "primary"
                    }
                    focused={
                      (modifiedValues?.partitaIva?.length ?? "") > 0 && sent
                    }
                    size="small"
                    type="text"
                    name="partitaIva"
                    label="p.iva"
                    variant={variant}
                    inputProps={{
                      style: {
                        textTransform:
                          personData.CSSTextTransform?.partitaIva ?? "",
                      },
                    }}
                  ></TextField>
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  value={modifiedValues?.iban ?? personData.iban ?? ""}
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.iban?.length ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  focused={(modifiedValues?.iban?.length ?? "") > 0 && sent}
                  size="small"
                  type="text"
                  name="iban"
                  label="iban"
                  variant={variant}
                  inputProps={{
                    style: {
                      textTransform: personData.CSSTextTransform?.iban ?? "",
                    },
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={modifiedValues?.swift ?? personData.swift ?? ""}
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.swift?.length ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  focused={(modifiedValues?.swift?.length ?? "") > 0 && sent}
                  size="small"
                  type="text"
                  name="swift"
                  label="swift"
                  variant={variant}
                  inputProps={{
                    style: {
                      textTransform: personData.CSSTextTransform?.swift ?? "",
                    },
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  value={modifiedValues?.note ?? personData.note ?? ""}
                  onChange={onChangeField}
                  fullWidth={true}
                  color={
                    (modifiedValues?.note?.length ?? "") > 0 && sent
                      ? "success"
                      : "primary"
                  }
                  focused={(modifiedValues?.note?.length ?? "") > 0 && sent}
                  size="small"
                  type="text"
                  name="note"
                  label="note"
                  variant={variant}
                  inputProps={{
                    style: {
                      textTransform: personData.CSSTextTransform?.note ?? "",
                    },
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  required
                  control={
                    <Checkbox
                      checked={
                        modifiedValues?.contrattoFirmato ??
                        personData?.contrattoFirmato
                      }
                      name={"contrattoFirmato"}
                      onChange={() => {
                        setModifiedValues({
                          ...modifiedValues,
                          contrattoFirmato: !modifiedValues.contrattoFirmato,
                        });
                      }}
                    />
                  }
                  label={
                    <Typography variant="body2">Contratto firmato</Typography>
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button
            type="submit"
            variant={sent ? "outlined" : "contained"}
            sx={{ color: sent ? "primary" : "white" }}
            color="primary"
          >
            {sent ? "SALVATO" : "SALVA"}{" "}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

function checkTipoPersona(personData, modifiedValues) {
  if (modifiedValues.tipoPersona) {
    return modifiedValues.tipoPersona !== "persona fisica";
  } else {
    return personData.tipoPersona !== "persona fisica";
  }
}

function convertDate(personDataDate, modifiedValuesDate) {
  if (personDataDate) {
    return dayjs(personDataDate);
  } else if (modifiedValuesDate) {
    return dayjs(modifiedValuesDate);
  } else {
    return undefined;
  }
}

function formatDate(date) {
  const dateObject = new Date(date);
  return `${dateObject.getDate()}/${
    dateObject.getMonth() + 1
  }/${dateObject.getFullYear()}`;
}

export default CardPersonDetails;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

import {
  Grid,
  TextField,
  Button,
  Paper,
  Container,
  ImageListItem,
} from "@mui/material";

import { useRecoilState } from "recoil";
import { user, token, isAuth } from "../../../athoms/auth";


import standardRequestGenerator from "../../../helpers/httpHelperV1";

import Logo from "../../../assets/images/LOGO-FOOTER.png";
import "./style.css";

const LogInCard = () => {
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [userState, setUserState] = useRecoilState(user);
  const [tokenState, setTokenState] = useRecoilState(token);
  const [authState, setAuthState] = useRecoilState(isAuth);
  const navigate = useNavigate();

  const logInApiV1 = standardRequestGenerator({
    url: "auth",
    queryType: "LOGIN",
    isAuthState: setAuthState,
    token: tokenState,
    body: formData,
  });

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
    e.preventDefault();
  };

  const submitHandler = (e) => {
    logInApiV1()
      .then((e) => {
        console.log(e);
        setUserState(e.body.rows[0]);
        setTokenState(e.body.rows[0].token);
        setAuthState(true);
        secureLocalStorage.setItem("user", e.body.rows[0]);
        secureLocalStorage.setItem("token", e.body.rows[0].token);
        navigate("/");
      })
      .catch((e) => alert(e));
    e.preventDefault();
  };
  return (
    <Paper className="login-card" elevation={16} sx={{ opacity: 0.9 }}>
      <form onSubmit={submitHandler}>
        <Grid
          container
          spacing={3}
          direction={"column"}
          justify={"center"}
          alignItems={"center"}
          sx={{ opacity: 1 }}
        >
          <Grid item lg={12}>
            <ImageListItem>
              <Container>
                <img src={Logo} style={{ width: 200, marginBottom: 25 }}></img>
              </Container>
            </ImageListItem>
          </Grid>
          <Grid item lg={12}>
            <TextField
              onChange={onChange}
              name="username"
              required
              label="Username"
              type={"text"}
            ></TextField>
          </Grid>
          <Grid item lg={12}>
            <TextField
              onChange={onChange}
              name="password"
              required
              label="Password"
              type={"password"}
            ></TextField>
          </Grid>
          <Grid item lg={12}>
            {/* <FormControlLabel
                control={
                  <Checkbox
                    // checked={}
                    // onChange={}
                    label={"Keep me logged in"}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label="Keep me logged in"
              /> */}
          </Grid>
          <Grid item lg={12}>
            <Button
              onSubmit={submitHandler}
              type="submit"
              size="large"
              variant="contained"
              sx={{ color: "white" }}
            >
              {" "}
              Login{" "}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default LogInCard;

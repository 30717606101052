import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Select,
  MenuItem,
  TextField,
  Paper,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";

import { postRequestGenerator } from "../../../helpers/httpHelper";
import standardRequestGenerator from "../../../helpers/httpHelperV1";

import { useRecoilState, useRecoilValue } from "recoil";

import { user, token, isAuth } from "../../../athoms/auth";
import DeleteAction from "../../IconActions/DeleteAction";
import UpdateAction from "../../IconActions/UpdateAction";

const ContactsData = ({
  contactData,
  contactTypes,
  setPersonData,
  personData,
  newRow,
  setNewRow,
}) => {
  const tokenState = useRecoilValue(token);
  const [isAuthState, setIsAuthState] = useRecoilState(isAuth);
  const [modifiedValues, setModifiedValues] = useState({});

  const windowWidth = useRef(window.innerWidth);

  const [sent, setSent] = useState(false);
  const variant = "outlined";

  const onChangeField = (e) => {
    setModifiedValues({
      ...modifiedValues,
      [e.target.name]: e.target.value,
    });
    e.preventDefault();
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  const onUpdate = (e) => {
    const removeAddress = standardRequestGenerator({
      url: `auto/people/${personData._id}`,
      token: tokenState,
      isAuthState: setIsAuthState,
      queryType: "REMOVE",
      body: { recapiti: [contactData._id] },
    });
    const addAddress = standardRequestGenerator({
      url: `auto/people/${personData._id}`,
      token: tokenState,
      isAuthState: setIsAuthState,
      queryType: "ADD",
      body: {
        recapiti: [
          {
            tipo: contactData.tipo,
            contatto: contactData.contatto,
            ...modifiedValues,
          },
        ],
      },
    });
    if (newRow) {
      addAddress()
        .then((e) => {
          setPersonData(e.body.rows[0]);
          setNewRow(false);
        })
        .catch((e) => alert("Compilare tutti i campi richiesti"));
    } else {
      removeAddress()
        .then(() => addAddress())
        .then((e) => setPersonData(e.body.rows[0]));
    }
  };

  const onDelete = (e) => {
    const removeAddress = standardRequestGenerator({
      url: `auto/people/${personData._id}`,
      token: tokenState,
      isAuthState: setIsAuthState,
      queryType: "REMOVE",
      body: { recapiti: [contactData._id] },
    });
    removeAddress().then(() => {
      setPersonData({
        ...personData,
        recapiti: personData.recapiti.filter((e) => e._id !== contactData._id),
      });
    });
    e.preventDefault();
  };

  return (
    <Paper
      elevation={0}
      sx={{
        boxShadow:
          "0px 5px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.035)",
      }}
    >
      <form autoComplete="off" noValidate onSubmit={onSubmit}>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={3}>
            <Select
              onChange={onChangeField}
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={modifiedValues?.tipo ?? contactData.tipo}
              name="tipo"
              variant={variant}
            >
              {contactTypes.map((e) => (
                <MenuItem key={e} value={e}>
                  <Typography
                    sx={{
                      textTransform: contactData.CSSTextTransform?.tipo ?? "",
                    }}
                  >
                    {e}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={7}>
            <TextField
              inputProps={{
                style: {
                  textTransform: contactData.CSSTextTransform?.contatto ?? "",
                },
              }}
              value={modifiedValues?.contatto ?? contactData?.contatto ?? ""}
              onChange={onChangeField}
              fullWidth={true}
              color={
                (modifiedValues?.contatto ?? "") > 0 && sent
                  ? "success"
                  : "primary"
              }
              size="small"
              type="text"
              name="contatto"
              label="contatto"
              variant={variant}
            ></TextField>
          </Grid>
          <Grid item xs={1}>
            {Object.keys(modifiedValues).length > 0 && !sent && (
              <UpdateAction onUpdate={onUpdate} />
            )}
          </Grid>
          <Grid item xs={1}>
            <DeleteAction onDelete={onDelete} />
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default ContactsData;
